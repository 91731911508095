export const P4 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <div className="ctpDocument">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div className="table-container">
          <table className="control-table" style={{ marginTop: 32, width: "100%" }}>
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td style={{ width: 20, maxWidth: 20 }}></td>
                <td style={{ width: 30, minWidth: 30 }}></td>
                <td style={{ width: 55, minWidth: 55 }}></td>
                <td style={{ width: 300, midWidth: 300 }}>
                  2. Intended system usage; and 3. Other attributes as required by the
                  organization or associated missions/business functions; j. Reviews accounts
                  for compliance with account management requirements [Assignment:
                  organization-defined frequency]; and k. Establishes a process for reissuing
                  shared/group account credentials (if deployed) when individuals are removed
                  from the group. Supplemental Guidance: Information system account types
                  include, for example, individual, shared, group, system, guest/anonymous,
                  emergency, developer/manufacturer/vendor, temporary, and service. Some of the
                  account management requirements listed above can be implemented by
                  organizational information systems. The identification of authorized users of
                  the information system and the specification of access privileges reflects
                  the requirements in other security controls in the security plan. Users
                  requiring administrative privileges on information system accounts receive
                  additional scrutiny by appropriate organizational personnel (e.g., system
                  owner, mission/business owner, or chief information security officer)
                  responsible for approving such accounts and privileged access. Organizations
                  may choose to define access privileges or other attributes by account, by
                  type of account, or a combination of both. Other attributes required for
                  authorizing access include, for example, restrictions on time-of-day,
                  day-of-week, and point-of-origin. In defining other account attributes,
                  organizations consider system-related requirements (e.g., scheduled
                  maintenance, system upgrades) and mission/business requirements, (e.g., time
                  zone differences, customer requirements, remote access to support travel
                  requirements). Failure to consider these factors could affect information
                  system availability. Temporary and emergency accounts are accounts intended
                  for short-term use. Organizations establish temporary accounts as a part of
                  normal account activation procedures when there is a need for short-term
                  accounts without the demand for immediacy in account activation.
                  Organizations establish emergency accounts in response to crisis situations
                  and with the need for rapid account activation. Therefore, emergency account
                  activation may bypass normal account authorization processes. Emergency and
                  temporary accounts are not to be confused with infrequently used accounts
                  (e.g., local logon accounts used for special tasks defined by organizations
                  or when network resources are unavailable). Such accounts remain available
                  and are not subject to automatic disabling or removal dates. Conditions for
                  disabling or deactivating accounts include, for example: (i) when
                  shared/group, emergency, or temporary accounts are no longer required; or
                  (ii) when individuals are transferred or terminated. Some types of
                  information system accounts may require specialized training. Related
                  controls: AC-3, AC-4, AC-5, AC-6, AC-10, AC-17, AC-19, AC-20, AU-9, IA-2,
                  IA-4, IA-5, IA-8, CM-5, CM-6, CM-11, MA-3, MA-4, MA-5, PL-4, SC-13. Potential
                  Assessment Methods and Objectives: The below information from the NIST SP
                  800-53 Revision 4 Security Catalog is intended to provide additional optional
                  mechanisms to take into account when reviewing this Security Control.
                  Assessments of the individual security requirements must still include a
                  response to the specific procedures/guidance stated in the correlated
                  Assessment Procedures. Examine: [SELECT FROM: Access control policy;
                  procedures addressing account management; security plan; information system
                  design documentation; information system configuration settings and
                  associated documentation; list of active system accounts along with the name
                  of the individual associated with each account; list of conditions for group
                  and role membership; notifications or records of recently transferred,
                  separated, or terminated employees; list of recently disabled information
                  system accounts along with the name of the individual associated with each
                  account; access authorization records; account management compliance reviews;
                  information system monitoring records; information system audit records;
                  other relevant documents or records]. Interview: [SELECT FROM: Organizational
                  personnel with account management responsibilities; system/network
                  administrators; organizational personnel with information security
                  responsibilities]. Test: [SELECT FROM: Organizational processes account
                  management on the information system; automated mechanisms for implementing
                  account management].
                </td>
                <td style={{ width: 50, minWidth: 50 }}></td>
                <td style={{ width: 50, minWidth: 50 }}></td>
                <td style={{ width: 20, minWidth: 20 }}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
