import "styles/documents.css";

const DocumentSheet = ({
  formId,
  state,
  dispatch,
  editable,
  pageNumber,
  header: Header,
  form: Form,
  footer: Footer,
}) => (
  <>
    <div className="letter">
      <div className="sheet" style={{ pageBreakAfter: "always" }}>
        <div className="content" style={{ height: '100%' }}>
          <div style={{
            marginLeft: 20, width: 770, height: '100%',
            display: 'grid', gridTemplateRows: 'auto 1fr auto'
          }}>
            <div style={{ padding: '5px', alignSelf: 'start' }}>
              <Header
                formId={formId}
                state={state}
                dispatch={dispatch}
                editable={editable}
                pageNumber={pageNumber}
              />
            </div>
            <div style={{ fontSize: 11, padding: '10px', height: '100%' }}>
              <Form
                formId={formId}
                state={state}
                dispatch={dispatch}
                editable={editable}
                pageNumber={pageNumber}
              />
            </div>
            <div style={{ padding: '5px', alignSelf: 'end' }}>
              <Footer
                formId={formId}
                state={state}
                dispatch={dispatch}
                editable={editable}
                pageNumber={pageNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default DocumentSheet;
