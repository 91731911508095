import { useAssignmentValue } from "../useFormAssignment";
import { DownOutlined } from "@ant-design/icons";
import { lowerCase } from "lodash";
import { useDocument } from "../DocumentEditor";

const EditableSelect = (props) => {
  const { applicationId, formId, fieldIds, fieldNames, state, dispatch, editable, options, pageNumber } =
    props;

  useDocument({
    page_number: pageNumber,
    field_type: "MULTIPLE_CHOICE_PICK_ONE",
  }, fieldNames);

  const fieldByName = state?.fieldAnswers?.find(
    (a) =>
      fieldNames?.some((fn) => fn === a.fieldname) && a.applicationAnswer.isSaved === true,
  );

  const fieldById = state?.fieldAnswers?.find(
    (a) => fieldIds?.some(fn => fn === a?.field?.id) && a.applicationAnswer.isSaved === true
  )

  const field = fieldByName || fieldById

  const SelectedLabel = options.find((opt) => opt.value === field?.field?.id)?.label;

  const handleChange = (e) => {
    e.preventDefault();

    if (!editable) return;

    const value = e.target.value;
    console.log("HandleChange", { value, });

    dispatch({
      type: "SET_ASSIGNMENT",
      state: {
        formId: formId,
        fieldId: e.target.value,
        questionType: "MULTIPLE_CHOICE_PICK_ONE",
        showAnswers: true,
      },
    });
  };

  const isAssigned = field?.answer !== undefined;
  const styleId =
    state?.fieldId && fieldIds.some((f) => f === state?.fieldId)
      ? "selectedAssignment"
      : isAssigned
        ? "savedAssignment"
        : "unsavedAssignment";

  return (
    <>
      {editable ? (
        <select
          className="documentSelect"
          style={{ ...props.style, outline: "none !important" }}
          id={editable ? styleId : ""}
          onChange={handleChange}
        >
          {options.map((o, i) => (
            <option key={i} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      ) : (
        <select
          disabled
          className="documentSelect"
          style={{ ...props.style, outline: "none !important" }}
          id={editable ? styleId : ""}
        >
          <option>{(!editable && SelectedLabel) || ""}</option>
        </select>
      )}
    </>
  );
};

export default EditableSelect;
