import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { AlignedEditableCheckbox, AlignedEditableRadio } from "..";
import { Row, Col } from "antd";
import DodSeal from "../Assets/dod_seal.png";

export const P2 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        className="ao-document"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 40,
        }}
      >
        <div className="attachment-1">
          <div style={{ width: 100 }}>
            <h1
              style={{
                textDecoration: "underline",
                fontWeight: "bolder",
                fontSize: 12,
                marginBottom: 16,
              }}
            >
              Attachment 1
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "flex-start",
              textAnchor: "center",
              textAlign: "center",
            }}
          >
            <p>Conditions for the </p>
            <div className="editable-wrapper">
              <EditableField {...elementProps} fieldName="1_subject_authorization_type" />
            </div>
            <p style={{ paddingLeft: 4, paddingRight: 4 }}>for</p>
            <div className="editable-wrapper">
              <EditableField {...elementProps} fieldName="1_memo_for_program" />
            </div>
            <p>; Authorization Termination Date </p>
            <br />
            <p> (ATD): </p>
            <div
              style={{
                width: 250,
                minWidth: 250,
                height: 22,
                paddingLeft: "13.5%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="1_subject_atd_date_m_d_y"
                fieldType="FULFILL_DATE"
              />
            </div>
          </div>
        </div>

        <div className="attachment-1-2">
          <div style={{ width: "100%", height: 30 }}>
            1. These conditions are necessary to maintain this Authorization:
          </div>
          <div style={{ marginTop: 12, marginLeft: 4 }}>
            <table style={{ width: "100%", height: "100%" }} border="1">
              <thead>
                <tr style={{ height: 20 }}>
                  <td style={{ width: 20 }}></td>
                  <td style={{ width: 120, fontWeight: "bolder", textAlign: "center" }}>
                    Condition
                  </td>
                  <td style={{ width: 100, fontWeight: "bolder", textAlign: "center" }}>
                    Due Date
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>
                    Provide 30, 60, 90, and 120-day AO briefings outlining current risk status
                    with updates to the AO Determination Brief.
                  </td>
                  <td>
                    <EditableField
                      {...elementProps}
                      fieldType="FULFILL_DATE"
                      fieldName="2_ao_brief_due_date_m_d_y"
                    />
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>Monthly reviews with the CRA.</td>
                  <td>Monthly</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td style={{ display: "flex", flexDirection: "row" }}>
                    <p> Execute penetration testing of the</p>
                    <div
                      style={{
                        width: 80,
                        marginLeft: 4,
                        marginRight: 4,
                        height: 18,
                        marginBottom: 0,
                      }}
                    >
                      <EditableField {...elementProps} fieldName="1_memo_for_program" />
                    </div>{" "}
                    <p>environment</p>
                  </td>
                  <td>
                    <EditableField
                      {...elementProps}
                      fieldName="2_pentesting_due_date_m_d_y"
                      fieldType="FULFILL_DATE"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
};

export const Footer = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <div className="footer">
      <div style={{ display: "flex", flexDirection: "row" }}>
        SUBJECT:{" "}
        <FieldWrapper styles={{ marginLeft: 4, marginRight: 4, width: 120 }}>
          <EditableField {...elementProps} fieldName="1_subject_authorization_type" />
        </FieldWrapper>{" "}
        for the{" "}
        <FieldWrapper styles={{ marginLeft: 4, marginRight: 4, width: 120 }}>
          <EditableField {...elementProps} fieldName="1_memo_for_program" />
        </FieldWrapper>
        ,
        <FieldWrapper styles={{ marginLeft: 4, marginRight: 4, width: 120 }}>
          <EditableField {...elementProps} fieldName="1_subject_authorization_type" />
        </FieldWrapper>
        . Authorization
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "7.5%",
        }}
      >
        {" "}
        Termination Date (ATD):{" "}
        <FieldWrapper styles={{ marginLeft: 4 }}>
          <EditableField
            {...elementProps}
            fieldName="1_subject_atd_date_m_d_y"
            fieldType="FULFILL_DATE"
          />
        </FieldWrapper>
        .
      </div>
    </div>
  );
};

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        width: 200,
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height,
        marginBottom: 12,
        ...styles,
      }}
    >
      {children}
    </div>
  );
};
