import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P12 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const CIMarking = {
    label: "Select Marking",
    value: "classification_information_classification_marking",
    fieldIds: [
      fieldId("classification_information_marking_top_secret"),
      fieldId("classification_information_marking_secret"),
      fieldId("classification_information_marking_confidential"),
      fieldId("classification_information_marking_cui"),
      fieldId("classification_information_marking_unclassified"),
      // document.variableFields[252]?.id,
      // document.variableFields[253]?.id,
      // document.variableFields[254]?.id,
      // document.variableFields[255]?.id,
      // document.variableFields[256]?.id,
    ],
    fieldNames: [
      "classification_information_marking_top_secret",
      "classification_information_marking_secret",
      "classification_information_marking_confidential",
      "classification_information_marking_cui",
      "classification_information_marking_unclassified",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>13. Classification Information</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={CIMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>13A. Highest Data Classification Processed</b>
                <div>Ref: (x)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13a_top_secret"
                      // fieldId={document.variableFields[257]?.id}
                    />
                  </span>
                  <span>
                    <b>Top Secret</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13a_secret"
                      // fieldId={document.variableFields[258]?.id}
                    />
                  </span>
                  <span>
                    <b>Secret</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13a_confidential"
                      // fieldId={document.variableFields[259]?.id}
                    />
                  </span>
                  <span>
                    <b>Confidential</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13a_cui"
                      // fieldId={document.variableFields[260]?.id}
                    />
                  </span>
                  <span>
                    <b>Controlled Unclassified Information (CUI)</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13a_unclassified"
                      // fieldId={document.variableFields[261]?.id}
                    />
                  </span>
                  <span>
                    <b>Unclassified</b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  13B. Is there a Dissemination Control/Releasability for the information
                  processed?
                </b>
                <div>Ref: (y) & (z)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13b_noforn"
                      // fieldId={document.variableFields[262]?.id}
                    />
                  </span>
                  <span>
                    <b>NOFORN</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13b_nato"
                      // fieldId={document.variableFields[263]?.id}
                    />
                  </span>
                  <span>
                    <b>NATO</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13b_five_eyes"
                      // fieldId={document.variableFields[264]?.id}
                    />
                  </span>
                  <span>
                    <b>FIVE EYES (FVEY)</b>
                  </span>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13b_other"
                      // fieldId={document.variableFields[265]?.id}
                    />
                  </span>
                  <div style={{ width: "100%", marginRight: 5 }}>
                    <b>Other</b>
                    <div style={{ width: "100%" }}>
                      <EditableField
                        {...elementProps}
                        fieldName="13b_other_additional"
                        // fieldId={document.variableFields[266]?.id}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13b_none"
                      // fieldId={document.variableFields[267]?.id}
                    />
                  </span>
                  <span>
                    <b>None</b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>13C. Does the system process SAP data?</b>
                <div>Ref: (aa)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13c_yes"
                      // fieldId={document.variableFields[268]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13c_no"
                      // fieldId={document.variableFields[269]?.id}
                    />
                  </span>
                  <span>
                    <b>No</b> Proceed to Section 14
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>13D. Security Classification Guide (SCG)</b>
                <div>Refs: (x) & (ab)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "100%", margin: "0 5px" }}>
                    <b>SCG Title</b>
                    <div style={{ width: "100%" }}>
                      <EditableField
                        {...elementProps}
                        fieldName="13d_scg_title"
                        // fieldId={document.variableFields[270]?.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>13E. Any Foreign National Users?</b>
                <div>Refs: (z) & (aa)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13e_yes"
                      // fieldId={document.variableFields[271]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="13e_no"
                      // fieldId={document.variableFields[272]?.id}
                    />
                  </span>
                  <span>
                    <b>No</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
