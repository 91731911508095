import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Typography, Row, Col, Card, Button, message } from "antd";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import Api from "api";
import useDocumentTitle from "hooks/useDocumentTitle";
import useAccessControl, { Permissions } from "hooks/useAccessControl";
import useEnumPresentation from "hooks/useEnumPresentation";
import useViewport from "hooks/useViewport";
import { ColumnsType } from "antd/es/table";
import { UserNode } from "types";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import CreateUser from "./CreateUser";
import SmartTable, { compareStrings } from "components/Common/Tables/index";
import CreateModal from "components/Administration/Modals/CreateModal";

const { Text } = Typography;

const ManageUsers: React.FC = () => {
  useDocumentTitle("Users");
  const { PBAC } = useAccessControl();
  const { PresentRoleName } = useEnumPresentation();
  const viewport = useViewport();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data, loading } = useQuery(Api.User.GetAll(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const users = data?.allUsers;
  console.log({ Users: users });

  const columns: ColumnsType<UserNode> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, user) => (
        <Text style={{ textTransform: "capitalize" }}>{user.fullname}</Text>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => PresentRoleName(role),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => compareStrings(a.email, b.email),
    },
    {
      title: "Action",
      align: "center",
      render: (_, user) => <Link to={`/administration/users/manage/${user.id}`}>Manage</Link>,
    },
  ];

  const headerActions = PBAC([Permissions.CreateUsers])
    ? [
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setCreateModalOpen(true)}
          role="create-user"
        >
          Create
        </Button>,
      ]
    : undefined;

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Users"
        icon={<UserOutlined style={{ color: "rgb(24, 144, 255)" }} />}
        actions={headerActions}
      />

      <Row align="middle">
        <Col span={24}>
          <Card className="shadowed-static" bodyStyle={viewport.card.styles.bodyStyle}>
            <SmartTable loading={loading} data={users} columns={columns} />
          </Card>
        </Col>
      </Row>

      {createModalOpen && (
        <CreateModal
          title="Create User"
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
        >
          <CreateUser onClose={() => setCreateModalOpen(false)} />
        </CreateModal>
      )}
      <Outlet />
    </>
  );
};

export default ManageUsers;
