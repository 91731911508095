import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P5 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const CRMarking = {
    label: "Select Marking",
    value: "cloud_ref_classification_marking",
    fieldIds: [
      fieldId("cloud_ref_marking_top_secret"),
      fieldId("cloud_ref_marking_secret"),
      fieldId("cloud_ref_marking_confidential"),
      fieldId("cloud_ref_marking_cui"),
      fieldId("cloud_ref_marking_unclassified"),
      // document.variableFields[106]?.id,
      // document.variableFields[107]?.id,
      // document.variableFields[108]?.id,
      // document.variableFields[109]?.id,
      // document.variableFields[110]?.id,
    ],
    fieldNames: [
      "cloud_ref_marking_top_secret",
      "cloud_ref_marking_secret",
      "cloud_ref_marking_confidential",
      "cloud_ref_marking_cui",
      "cloud_ref_marking_unclassified",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderTop: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>7. Cloud Ref:</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={CRMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7A. Is the System going to be cloud-hosted?
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7a_yes"
                      // fieldId={document.variableFields[111]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7a_no"
                      // fieldId={document.variableFields[112]?.id}
                    />
                  </span>
                  <b>No</b> Proceed to Section 8
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7B. Will the System process classified information? Ref: (g)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7b_yes"
                      // fieldId={document.variableFields[113]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (Impact Level 6 (IL6))
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7b_no"
                      // fieldId={document.variableFields[114]?.id}
                    />
                  </span>
                  <b>No</b> Proceed to Quetion 7E
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7C. Does the System contain Personally Identifiable Information (PII) other
                than rolodex information? Ref: (h)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7c_yes"
                      // fieldId={document.variableFields[115]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (IL6 with Privacy Overlay)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7c_no"
                      // fieldId={document.variableFields[116]?.id}
                    />
                  </span>
                  <b>No</b> (IL6)
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  7D. Does your System retrieve information by a unique identifier (i.e., SSN,
                  Name, DOB, etc.)? Refs: (i), (j), (k), and (l)
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%", paddingRight: "5px" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7d_yes"
                      // fieldId={document.variableFields[117]?.id}
                    />
                  </span>
                  <div style={{ width: "100%" }}>
                    <b>Yes</b> Provide System of Records Notice (SORN) Number and Proceed to
                    Section 8
                    <div>
                      <EditableField
                        {...elementProps}
                        fieldName="7d_yes_additional"
                        // fieldId={document.variableFields[118]?.id}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7d_no"
                      // fieldId={document.variableFields[119]?.id}
                    />
                  </span>
                  <b>No</b> Proceed to Section 8
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7E. Does the System contain Controlled Unclassified Information (CUI) as
                defined in DoD Instruction (DoDI) 5200.48 Ref: (a) other than Low Impact PII?
                Refs: (k) & (m)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7e_yes"
                      // fieldId={document.variableFields[120]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> Proceed to Question 7G
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7e_no"
                      // fieldId={document.variableFields[121]?.id}
                    />
                  </span>
                  <b>No</b> (IL2) Response requires corresponding entry below
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="7e_no_low_impact_pii"
                      // fieldId={document.variableFields[122]?.id}
                    />
                  </span>
                  <b>Low Impact PII </b> (Must Answer Questions 12D - 12G & 12I)
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="7e_no_public_facing_website"
                      // fieldId={document.variableFields[123]?.id}
                    />
                  </span>
                  <b>Public-facing Website</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7F. Does your System retrieve information by a unique identifier (i.e., SSN,
                Name, DOB, etc.)? Refs: (i), (j), (k), & (l)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%", paddingRight: "5px" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7f_yes"
                      // fieldId={document.variableFields[124]?.id}
                    />
                  </span>
                  <div style={{ width: "100%" }}>
                    <b>Yes</b> Provide SORN Number then Proceed to Section 8
                    <div style={{ width: "100%" }}>
                      <EditableField
                        {...elementProps}
                        fieldName="7f_yes_additional"
                        // fieldId={document.variableFields[125]?.id}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7f_no"
                      // fieldId={document.variableFields[126]?.id}
                    />
                  </span>
                  <b>No</b> Proceed to Section 8
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7G. Is the System an NSS (IAW Section 11), or does the System contain
                information reflected in Ref: (n)?
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7g_yes"
                      // fieldId={document.variableFields[127]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (Impact Level 5 (IL5))
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7g_no"
                      // fieldId={document.variableFields[128]?.id}
                    />
                  </span>
                  <b>No</b> (Impact Level 4 (IL4))
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7H. Does the System contain PII other than rolodex information? Ref: (h)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7h_yes"
                      // fieldId={document.variableFields[129]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> Privacy Overlay Required
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7h_no"
                      // fieldId={document.variableFields[130]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                7I. Does your System retrieve information by a unique identifier (i.e., SSN,
                Name, DOB, etc.)? Refs: (i), (j), (k), & (l)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                borderRight: "none",
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%", paddingRight: "5px" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7i_yes"
                      // fieldId={document.variableFields[131]?.id}
                    />
                  </span>
                  <div style={{ width: "100%" }}>
                    <b>Yes</b> Provide SORN Number then Proceed to Section 8
                    <div>
                      <EditableField
                        {...elementProps}
                        fieldName="7i_yes_additional"
                        // fieldId={document.variableFields[132]?.id}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="7i_no"
                      // fieldId={document.variableFields[133]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
