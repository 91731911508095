import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, InformationTypeSelect } from "..";

const informationSelectStyle = {
  fontSize: ".7rem",
  border: "none",
  width: "100%",
  height: "100%",
};

export const P13 = (props) => {
  const { document, state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const confidentialityNames = [
    "categorization_information_marking_top_secret",
    "categorization_information_marking_secret",
    "categorization_information_marking_confidential",
    "categorization_information_marking_cui",
    "categorization_information_marking_unclassified",
  ];

  const CIMarking = {
    label: "Select Marking",
    value: "categorization_information_classification_marking",
    fieldIds: [
      fieldId("categorization_information_marking_top_secret"),
      fieldId("categorization_information_marking_secret"),
      fieldId("categorization_information_marking_confidential"),
      fieldId("categorization_information_marking_cui"),
      fieldId("categorization_information_marking_unclassified"),
      // document.variableFields[273]?.id,
      // document.variableFields[274]?.id,
      // document.variableFields[275]?.id,
      // document.variableFields[276]?.id,
      // document.variableFields[277]?.id,
    ],
    fieldNames: confidentialityNames,
  };

  let fieldIndex = 278;

  const confidentialityConfig = (row, names = []) => ({
    label: "Select One",
    value: `r${row}confidentiality_information_type`,
    fieldIds: names.map(
      (n) => state?.document?.variableFields.find((f) => f.fieldName === n)?.id,
    ),
    fieldNames: names,

    // fieldIds: state?.document?.variableFields.find((f) =>
    //   ids.includes(f.fieldName),
    // )?.id,

    // fieldIds: Array.from(Array(num).keys()).map(() => {
    //   // return fieldIndex++;
    //   return document.variableFields[fieldIndex++]?.id;
    // }),
  });

  const integrityConfig = (row, names = []) => ({
    label: "Select One",
    value: `r${row}integrity_information_type`,
    fieldIds: names.map(
      (n) => state?.document?.variableFields.find((f) => f.fieldName === n)?.id,
    ),
    fieldNames: names,
    // fieldIds: Array.from(Array(num).keys()).map(() => {
    //   // return fieldIndex++;
    //   return state?.document?.variableFields[fieldIndex++]?.id;
    // }),
  });

  const availabilityConfig = (row, names = []) => ({
    label: "Select One",
    value: `r${row}availability_information_type`,
    fieldIds: names.map(
      (n) => state?.document?.variableFields.find((f) => f.fieldName === n)?.id,
    ),
    fieldNames: names,
    // fieldIds: Array.from(Array(num).keys()).map(() => {
    //   // return fieldIndex++;
    //   return state?.document?.variableFields[fieldIndex++]?.id;
    // }),
  });

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <b style={{ flex: 1, fontSize: 14 }}>14. Categorization Information</b>

            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={CIMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              padding: "10px 0",
            }}
          >
            <i>
              NOTE 16: Categorize the CIA for APPLICABLE Information Types (i.e., Low,
              Moderate, or High) IAW Refs: (o), (ac), & (ad).
            </i>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.23,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Information Types</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Confidentiality</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Integrity</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Availability</b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.23,
                borderRight: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  backgroundColor: "lightgrey",
                }}
              >
                <b>Amplifying Data</b>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r1_information_type"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(1, [
                    "14_r1_confidentiality_high",
                    "14_r1_confidentiality_moderate",
                    "14_r1_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(1, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(1, [
                    "14_r1_integrity_high",
                    "14_r1_integrity_moderate",
                    "14_r1_integrity_low",
                  ])}
                  // config={integrityConfig(1, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(1, [
                    "14_r1_availability_high",
                    "14_r1_availability_moderate",
                    "14_r1_availability_low",
                  ])}
                  // config={availabilityConfig(1, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r1_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r2_information_type"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(2, [
                    "14_r2_confidentiality_high",
                    "14_r2_confidentiality_moderate",
                    "14_r2_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(2, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(2, [
                    "14_r2_integrity_high",
                    "14_r2_integrity_moderate",
                    "14_r2_integrity_low",
                  ])}
                  // config={integrityConfig(2, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(2, [
                    "14_r2_availability_high",
                    "14_r2_availability_moderate",
                    "14_r2_availability_low",
                  ])}
                  // config={availabilityConfig(2, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r2_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r3_information_type"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(3, [
                    "14_r3_confidentiality_high",
                    "14_r3_confidentiality_moderate",
                    "14_r3_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(3, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(3, [
                    "14_r3_integrity_high",
                    "14_r3_integrity_moderate",
                    "14_r3_integrity_low",
                  ])}
                  // config={integrityConfig(3, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(3, [
                    "14_r3_availability_high",
                    "14_r3_availability_moderate",
                    "14_r3_availability_low",
                  ])}
                  // config={availabilityConfig(3, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r3_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r4_information_type"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(4, [
                    "14_r4_confidentiality_high",
                    "14_r4_confidentiality_moderate",
                    "14_r4_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(4, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(4, [
                    "14_r4_integrity_high",
                    "14_r4_integrity_moderate",
                    "14_r4_integrity_low",
                  ])}
                  // config={integrityConfig(4, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(4, [
                    "14_r4_availability_high",
                    "14_r4_availability_moderate",

                    "14_r4_availability_low",
                  ])}
                  // config={availabilityConfig(4, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r4_amplifying_data"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r5_information_type"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(5, [
                    "14_r5_confidentiality_high",
                    "14_r5_confidentiality_moderate",
                    "14_r5_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(5, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(5, [
                    "14_r5_integrity_high",
                    "14_r5_integrity_moderate",
                    "14_r5_integrity_low",
                  ])}
                  // config={integrityConfig(5, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(5, [
                    "14_r5_availability_high",
                    "14_r5_availability_moderate",
                    "14_r5_availability_low",
                  ])}
                  // config={availabilityConfig(5, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r5_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r6_information_type"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(6, [
                    "14_r6_confidentiality_high",
                    "14_r6_confidentiality_moderate",
                    "14_r6_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(6, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(6, [
                    "14_r6_integrity_high",
                    "14_r6_integrity_moderate",
                    "14_r6_integrity_low",
                  ])}
                  // config={integrityConfig(6, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(6, [
                    "14_r6_availability_high",
                    "14_r6_availability_moderate",
                    "14_r6_availability_low",
                  ])}
                  // config={availabilityConfig(6, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r6_amplifying_data"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r7_information_type"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(7, [
                    "14_r7_confidentiality_high",
                    "14_r7_confidentiality_moderate",
                    "14_r7_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(7, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(7, [
                    "14_r7_integrity_high",
                    "14_r7_integrity_moderate",
                    "14_r7_integrity_low",
                  ])}
                  // config={integrityConfig(7, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(7, [
                    "14_r7_availability_high",
                    "14_r7_availability_moderate",
                    "14_r7_availability_low",
                  ])}
                  // config={availabilityConfig(7, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r7_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r8_information_type"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(8, [
                    "14_r8_confidentiality_high",
                    "14_r8_confidentiality_moderate",
                    "14_r8_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(8, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(8, [
                    "14_r8_integrity_high",
                    "14_r8_integrity_moderate",
                    "14_r8_integrity_low",
                  ])}
                  // config={integrityConfig(8, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(8, [
                    "14_r8_availability_high",
                    "14_r8_availability_moderate",
                    "14_r8_availability_low",
                  ])}
                  // config={availabilityConfig(8, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r8_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r9_information_type"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(9, [
                    "14_r9_confidentiality_high",
                    "14_r9_confidentiality_moderate",
                    "14_r9_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(9, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(9, [
                    "14_r9_integrity_high",
                    "14_r9_integrity_moderate",
                    "14_r9_integrity_low",
                  ])}
                  // config={integrityConfig(9, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(9, [
                    "14_r9_availability_high",
                    "14_r9_availability_moderate",
                    "14_r9_availability_low",
                  ])}
                  // config={availabilityConfig(9, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r9_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r10_information_type"
                  //  fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(10, [
                    "14_r10_confidentiality_high",
                    "14_r10_confidentiality_moderate",
                    "14_r10_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(10, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(10, [
                    "14_r10_integrity_high",
                    "14_r10_integrity_moderate",
                    "14_r10_integrity_low",
                  ])}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(10, [
                    "14_r10_availability_high",
                    "14_r10_availability_moderate",
                    "14_r10_availability_low",
                  ])}
                  // config={availabilityConfig(10, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r10_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r11_information_type"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(11, [
                    "14_r11_confidentiality_high",
                    "14_r11_confidentiality_moderate",
                    "14_r11_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(11, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={integrityConfig(11, [
                    "14_r11_integrity_high",
                    "14_r11_integrity_moderate",
                    "14_r11_integrity_low",
                  ])}
                  // config={integrityConfig(11, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={availabilityConfig(11, [
                    "14_r11_availability_high",
                    "14_r11_availability_moderate",
                    "14_r11_availability_low",
                  ])}
                  // config={availabilityConfig(11, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r11_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  padding: "0 10px",
                  width: "100%",
                  height: "100%",
                  fontSize: 12,
                }}
              >
                <b>FINAL SECURITY CATEGORIZATION</b>
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "60px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  elementProps={elementProps}
                  config={confidentialityConfig(12, [
                    "14_r12_confidentiality_high",
                    "14_r12_confidentiality_moderate",
                    "14_r12_confidentiality_low",
                  ])}
                  // config={confidentialityConfig(12, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.18,
                borderRight: "1px solid black",
                height: "60px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  borderless
                  elementProps={elementProps}
                  config={integrityConfig(12, [
                    "14_r12_integrity_high",
                    "14_r12_integrity_moderate",
                    "14_r12_integrity_low",
                  ])}
                  // config={integrityConfig(12, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.18,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "60px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <InformationTypeSelect
                  borderless
                  elementProps={elementProps}
                  config={availabilityConfig(12, [
                    "14_r12_availability_high",
                    "14_r12_availability_moderate",
                    "14_r12_availability_low",
                  ])}
                  // config={availabilityConfig(12, 3)}
                  style={informationSelectStyle}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.23,
                display: "flex",
                flexDirection: "column",
                borderRight: "none",
                height: "60px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="14_r12_amplifying_data"
                  // fieldId={fieldConfig(1)}
                />
                {/* {(() => {
                  console.log("next id", fieldConfig(1));
                })()} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
