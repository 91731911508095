import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P15 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 12,
          paddingTop: 20,
        }}
      >
        <div style={{ paddingLeft: 20 }}>
          <div style={{ fontSize: 16 }}>
            <p>
              <b>Appendix C — Definitions</b>
            </p>
          </div>
          <p>
            <u>The “Rolodex Exception”</u>
          </p>
          <p>
            OMB M-07-16, Footnote 6, establishes the flexibility for an organization to
            determine the sensitivity of its PII in context using a best judgment standard. The
            example provided in footnote 6 addresses an office rolodex and recognizes the low
            sensitivity of business contact information used in the limited context of
            contacting an individual through the normal course of a business interaction. The
            Privacy Overlays refers to this example from OMB M-07-16, Footnote 6, as the
            “Rolodex Exception.” PII meeting the “Rolodex Exception” typically presents a very
            low risk to privacy for the individual or the organization and will not trigger
            implementation of the low, moderate, or high Privacy Overlays for a system
            containing only this type of information. Consistent with NIST and CNSS tailoring
            guidance, the “Rolodex Exception” is a scoping decision that, when applicable,
            helps organizations avoid unnecessary expenditures of resources based on a risk
            determination for this limited subset of PII.
          </p>

          <p>
            For the purposes of implementing the low, moderate, and high Privacy Overlays, PII
            that may be included in this “Rolodex Exception” is limited to the following
            business contact information:
          </p>

          <ul>
            <li>Name (full or partial)</li>
            <li>Business street address</li>
            <li>Business phone numbers, including fax</li>
            <li>Business e-mail addresses</li>
            <li>Business organization</li>
          </ul>

          <p>
            An example of an information system which may meet the parameters of the Rolodex
            Exception include office rosters that contain only business contact information.
          </p>

          <p>
            Before choosing to apply the Rolodex Exception, an organization must consider the
            sensitivity of the PII based on the complete context in which it appears. Business
            contact information alone can be sensitive under certain circumstances, such as in
            association with a tax return or on a list of individuals under investigation for
            fraud, waste, and abuse. Consider, also, whether the contact information includes a
            blend of business and personal information (e.g., a business phone number may be a
            personal device, or a business address may be a residential address of a home
            office). If, after exploring contextual considerations, the organization determines
            that a system’s use of the business contact information is limited to business
            contact purposes, then the organization may apply the Rolodex Exception.
          </p>

          <p>
            This analysis must include an evaluation of related operational security issues,
            which are distinct from privacy considerations and may require additional
            protective measures. Application of this Rolodex Exception is limited to the
            Privacy Overlays and does not affect applicability of any other statute,
            regulation, or standard which may require consideration and protection of this type
            of information in other contexts. For example, consider business contact
            information which both meets the terms of the Rolodex Exception and appears in a
            context that has increased classification or operational security sensitivities;
            the Rolodex Exception may obviate the organization from implementing the Privacy
            Overlays, but the organization must still meet requirements that are applicable to
            protect classified information and resolve operational security concerns.
          </p>
        </div>
      </div>
    </>
  );
};
