import { useParams } from "react-router-dom";
import { useAssignmentValue } from "../useFormAssignment";
import { Button } from "antd";
import { useDocument } from "../DocumentEditor";

const EditableRadio = (props) => {
  const {
    // applicationId,
    formId,
    fieldName,
    pageNumber,
    // fieldId,
    state,
    dispatch,
    editable,
    style,
    contentStyle,
    button = false,
    label = '',
    group = null,
    extra = null,
    test = false
  } = props;

  const { applicationId } = useParams();

  useDocument({
    page_number: pageNumber,
    field_name: fieldName,
    field_type: "MULTIPLE_CHOICE_PICK_ONE",
  });

  const fields = state?.document?.variableFields;
  const field = fields?.find((f) => f.fieldName === fieldName);
  const fieldId = field?.id;

  // only when editable true
  const answerObj = state?.fieldAnswers?.find((a) => a.fieldname === fieldName);

  const assignment = useAssignmentValue({
    applicationId,
    formId,
    editable,
    fieldIds: [fieldId],
  });

  if (assignment) console.log("EditableRadioAssignment", { Assignment: assignment });

  const checked = answerObj?.applicationAnswer?.isSaved;

  // console.log("EditableRadio: ", {
  //   checked,
  //   field,
  //   answer: answerObj?.applicationAnswer?.answer,
  // });

  const handleClick = (e) => {
    e.preventDefault();
    if (!editable) return;

    dispatch({
      type: "SET_ASSIGNMENT",
      state: {
        formId: formId,
        fieldId: fieldId,
        questionType: "MULTIPLE_CHOICE_PICK_ONE",
        showAnswers: true,
      },
    });
  };

  const fieldType = "MULTIPLE_CHOICE_PICK_ONE"

  const missingAssignment = !field && "missingAssignment";
  const selectedAssignment =
    (state?.fieldId && state?.fieldId === fieldId) && "selectedAssignment";
  const isAssigned = (field?.answer !== null) ? "savedAssignment" : "unsavedAssignment";
  const mismatchedTypes = fieldType !== field?.fieldType && "mismatchTypes"
  const styleId = missingAssignment || selectedAssignment || mismatchedTypes || isAssigned;



  if (button) {
    return (
      <Button
        className="documentRadio"
        size="small"
        htmlType="radio"
        style={{
          fontSize: '11px',
          pointerEvents: editable ? '' : 'none'
        }}
        id={editable ? styleId : checked ? "answeredButton" : ""}
        disabled={!editable}
        onClick={handleClick}
      >
        {label}
      </Button>
    )
  }

  if (label === '') {
    return <input
      readOnly
      type="radio"
      className="documentRadio"
      id={editable ? styleId : ""}
      name={group}
      checked={checked || false}
      onClick={handleClick}
    />
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%', ...style }}>
        <input
          readOnly
          style={{ alignSelf: 'center' }}
          type="radio"
          className="documentRadio"
          id={editable ? styleId : ""}
          name={group}
          checked={checked || false}
          onClick={handleClick}
        />
        <div style={{ display: 'flex', marginLeft: '4px', width: '100%', alignItems: 'center', ...contentStyle }}>
          {label}
          {extra}
        </div>
      </div>
    </>
  )
}

export default EditableRadio;
