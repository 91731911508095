import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import {
  ClassificationSelect,
  EmassClassificationSelect,
  FirstCategorySubmissionSelect,
  RmfActionSelect,
} from "..";

export const P2 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props?.pageNumber
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const SSIMarking = {
    label: "Select Marking",
    value: "ssi_classification_marking",
  };

  const AOABMarking = {
    label: "Select Marking",
    value: "sos_classification_marking",
  };

  // prettier-ignore
  const FirstCategorySubmissionConfig = {
    label: "",
    value: "first_categorization_submission",
    fieldIds: [
      fieldId('first_categorization_submission_yes'),
      fieldId('first_categorization_submission_no'),
    ],
    fieldNames: [
      "first_categorization_submission_yes",
      "first_categorization_submission_no",
    ]
  };

  const EmassClassificationConfig = {
    label: "",
    value: "emass_classification",
    fieldIds: [
      fieldId("emass_classification_nipr"),
      fieldId("emass_classification_sipr"),
      fieldId("emass_classification_na"),
    ],
    fieldNames: [
      "emass_classification_nipr",
      "emass_classification_sipr",
      "emass_classification_na",
    ],
  };


  const Q2Radio = ({ fieldName, children }) => {
    return <EditableRadio {...elementProps} fieldName={fieldName} label={children} />
  }

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        {/* 1 System Identification Information */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} />
              <col span={1} width={'55%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="text" colSpan={2}>
                  The purpose of Prepare step of the RMF is to identify essential activities of organization, mission, and business processes. The<br />
                  PM/RMF team is required to fill out the DAF ITCSC during this step to identify and prepare for the management of<br />
                  cybersecurity and privacy risks.<br />
                  <i>Refs: (d) & (e)</i>
                </th>
              </tr>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={SSIMarking} style={{ border: 'none' }} />
                    </div>
                    <div>1. System Identification Information</div>
                  </div>

                </th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td className="label">System Name:</td>
                <td><EditableField borderless {...elementProps} fieldName="system_name" /></td>
              </tr>

              <tr>
                <td className="label">System Acronym:</td>
                <td><EditableField borderless {...elementProps} fieldName="system_acronym" /></td>
              </tr>

              <tr>
                <td className="label">Version:</td>
                <td><EditableField borderless {...elementProps} fieldName="version" /></td>
              </tr>
              <tr>
                <td className="label">First Categorization Submission:</td>
                <td><FirstCategorySubmissionSelect style={{ width: "100%", border: "none" }} elementProps={elementProps} config={FirstCategorySubmissionConfig} /></td>
              </tr>
              <tr>
                <td className="label">Is it a System or Subsystem? (Choose one)</td>
                <td>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                    <EditableRadio {...elementProps} fieldName="system_or_subsystem_system" button label="System" />
                    <EditableRadio {...elementProps} fieldName="system_or_subsystem_subsystem" button label="Subsystem" />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="label">IT Investment Portfolio Suite (ITIPS) ID:</td>
                <td><EditableField borderless {...elementProps} fieldName="it_investment_portfolio" /></td>
              </tr>
              <tr>
                <td className="label">Enterprise Mission Assurance Support Service (eMASS) ID:</td>
                <td><EditableField borderless {...elementProps} fieldName="emass_id" /></td>
              </tr>
              <tr>
                <td className="label">eMASS Classification:</td>
                <td><EmassClassificationSelect style={{ width: "100%", border: "none" }} elementProps={elementProps} config={EmassClassificationConfig} /></td>
              </tr>
              <tr>
                <td className="label">DOD Information Technology Portfolio Repository (DITPR) ID:</td>
                <td><EditableField borderless {...elementProps} fieldName="ditpr" /></td>
              </tr>
              <tr>
                <td className="label">Air Force ID (AFID):</td>
                <td><EditableField borderless {...elementProps} fieldName="afid" /></td>
              </tr>
              <tr>
                <td colSpan={2} className="text" style={{ padding: '6px 10px' }}>
                  <i>
                    Note 1: In cases where <u>no</u> Personally Identifiable Information (PII) / Protected Health Information (PHI) is present, the DAF<br />
                    ITCSC will serve as a conclusive determination that privacy requirements do not apply to the system.<br />
                    Note 2: Systems that do contain PII / PHI will need to complete a Privacy Impact Assessment (PIA) in conjunction with an<br />
                    organizational privacy subject matter expert.<br />
                  </i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        {/*2. AO Boundary (Select One) */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
              <col span={1} width={'25%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={4}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={AOABMarking} style={{ border: 'none' }} />
                    </div>
                    <div>2. AO Boundary (Select One) <i>Ref: f</i></div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ verticalAlign: 'top' }} >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 0.25,
                      rowGap: '5px',
                      paddingLeft: '8px'
                    }}
                  >
                    <EditableRadio {...elementProps} fieldName="aoab_af_offensive_cyber_operations_cyberspace_weapons_systems"
                      label={<>Air Force Offensive Cyber Operations & Cyberspace Weapons Systems</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_af_operational_and_evaluation"
                      label={<>Air Force Operational and Evaluation (AFOTEC)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_af_special_operations_command"
                      label={<>Air Force Special Operations Command (AFSOC)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_af_sustainment_center"
                      label={<>Air Force Sustainment<br /> Center (AFSC) Software Enterprise (SWENT)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_af_weather_update_systems"
                      label={<>Air Force Weather Weapon System (AFWWS)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_aircraft"
                      label={<>Aircraft</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_civil_engineering_control_systems"
                      label={<>Civil Engineering (CE)<br /> Control Systems (CS)</>} />
                  </div>
                </td>
                <td style={{ verticalAlign: 'top' }} >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 0.25,
                      rowGap: '7px',
                      paddingLeft: '8px'
                    }}
                  >
                    <EditableRadio {...elementProps} fieldName="aoab_ce_infomation_technology_and_ce_platforms"
                      label={<>CE IT and CE Platforms</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_command_and_control"
                      label={<>Command and Control (C2)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_cyberspace_innovation"
                      label={<>Cyberspace Innovation</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_daf_cloud_and_dev_sec_ops"
                      label={<>DAF Cloud and DevSecOps</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_daf_enterprise"
                      label={<>DAF Enterprise</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_dod_cyber_crime_center"
                      label={<>DoD Cyber Crime Center (DC3)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_dod_mission_partner"
                      label={<>DOD Mission Partner<br />Environment (DOD MPE)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_depot_support_systems"
                      label={<>Depot Support Systems (DSS)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_development_test_and_evaluation"
                      label={<>Developmental Test and<br /> Evaluation (DT&E)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_finance"
                      label={<>Finance</>} />
                  </div>
                </td>
                <td style={{ verticalAlign: 'top' }} >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 0.25,
                      rowGap: '8px',
                      paddingLeft: '8px'

                    }}
                  >
                    <EditableRadio {...elementProps} fieldName="aoab_force_development"
                      label={<>Force Development</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_headquarters_air_force"
                      label={<>Headquarters Air Force (HAF)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_human_resources_management"
                      label={<>Human Resource Management (HRM)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_industrial_depot_maintenance"
                      label={<>Industrial Depot Maintenance (IDM)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_logistics"
                      label={<>Logistics</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_operational_test_and_training_infrastructure"
                      label={<>Operational Test and Training<br />Infrastructure (OTTI)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_rapid_cyber_acquisition"
                      label={<>Rapid Cyber Acquisition <br />(RCA)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_science_and_technology"
                      label={<>Science & Technology (S&T)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_security_forces_force_protection"
                      label={<>Security Forces / Force<br />Protection</>} />

                  </div>
                </td>
                <td style={{ verticalAlign: 'top' }} >
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 0.25,
                    rowGap: '5px',
                    paddingBottom: '10px',
                    paddingLeft: '8px'
                  }} >
                    <EditableRadio {...elementProps} fieldName="aoab_special_access_programs"
                      label={<>Special Access Program<br /> (SAP)</>} />
                    <EditableRadio {...elementProps} fieldName="aoab_strategic_systems"
                      label={<>Strategic Systems</>} />
                    <EditableRadio {...elementProps} fieldName="aoab_us_space_force_in_europe_usafe"
                      label={<>US Air Forces in Europe (USAFE)</>} />
                    <EditableRadio {...elementProps} fieldName="aoab_us_space_force_ussf"
                      label={<>US Space Force (USSF)</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_us_space_force_space_systems_command_aerospace_systems_ssc"
                      label={<>USSF Space Systems <br />Command Aerospace (SSC)<br /> Research, Development,<br /> Testing, and Engineering<br /> Systems</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_weapons"
                      label={<>Weapons</>} />

                    <EditableRadio {...elementProps} fieldName="aoab_other_17-101_pick_one" test
                      label={<>Other (Please specify - See <br />Air Force Instruction (AFI) <br />17-101)</>}
                      extra={<EditableField {...elementProps} fieldName="aoab_other_17-101_fulfill_text" />}
                      contentStyle={{ flexDirection: 'column' }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <i style={{ paddingLeft: 5, textAlign: "left" }}>
                    Note 3. A listing of AOs and AO boundary descriptions can be found on the DAF RMF Knowledge Service (
                    <a href="https://rmfks.osd.mil/rmf/Collaboration/Component%20Workspaces/AirForce/Lists/Air%20Force%20Authorization%20Boundaries/AllItems.aspx">
                      https://rmfks.osd.mil/<br />
                      rmf/Collaboration/Component%20Workspaces/AirForce/Lists/Air%20Force%20Authorization%20Boundaries/AllItems.aspx
                    </a>
                    )
                  </i>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
