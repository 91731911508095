import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";

export const ITCSCv902Pages = pages;

export const ITCSCv902Header = (props) => {
  const document = props?.state?.document;
  const pageNumber = props?.pageNumber || 0

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: pageNumber
  };

  const fieldNames = [
    "document_classification_top_secret",
    "document_classification_secret",
    "document_classification_confidential",
    "document_classification_cui",
    "document_classification_unclassified",
    "document_classification_tssci",
  ];

  return (
    <Row justify="center" align="top" style={{ marginTop: 20 }}>
      <div style={{ width: "150px", textAlign: 'center' }}>
        {pageNumber === 1 ? <h2 style={{ marginBottom: '0px' }}>OVERALL</h2> : null}
        {pageNumber < 14 ?
          <SheetClassification
            fieldNames={fieldNames}
            elementProps={elementProps}
            document={document}
          /> : <></>
        }
      </div>
    </Row>
  );
};

export const ITCSCv902Footer = (props) => {
  const style = {
    paddingTop: 5,
    marginBottom: 0,
    fontSize: 11,
  };

  const { state, pageNumber } = props;

  const document = state?.document;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: pageNumber
  };

  const fieldNames = [
    "document_classification_top_secret",
    "document_classification_secret",
    "document_classification_confidential",
    "document_classification_cui",
    "document_classification_unclassified",
    "document_classification_tssci",
  ];

  // console.log({ document, state: props?.state });

  return (
    <Row justify="space-between" align='bottom'>
      <Col span={8} style={{ verticalAlign: 'bottom' }}>
        <p style={style}>DAF ITCSC v9.0.2, 16 Aug 2023</p>
      </Col>
      <Col span={4.5}>
        <div style={{ width: "150px", textAlign: 'center' }}>
          {pageNumber === 1 ? <h2 style={{ marginBottom: '0px' }}>OVERALL</h2> : null}
          {pageNumber < 14 ?
            <SheetClassification
              fieldNames={fieldNames}
              elementProps={elementProps}
              document={document}
            /> : <></>
          }
        </div>
      </Col>
      <Col span={8}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {document?.numberOfPages}
        </p>
      </Col>
    </Row>
  );
};

export const ClassificationSelect = ({ elementProps, config, style = {} }) => {
  const fieldId = (fName) =>
    elementProps?.state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  var fieldIds = config.fieldIds || [
    fieldId(`${config.value}_top_secret`),
    fieldId(`${config.value}_secret`),
    fieldId(`${config.value}_confidential`),
    fieldId(`${config.value}_cui`),
    fieldId(`${config.value}_unclassified`),
    fieldId(`${config.value}_tssci`)
  ]
  var fieldNames = config.fieldNames || [
    `${config.value}_top_secret`,
    `${config.value}_secret`,
    `${config.value}_confidential`,
    `${config.value}_cui`,
    `${config.value}_unclassified`,
    `${config.value}_tssci`
  ]

  if (fieldIds.length < 1) console.log("no fieldIds in config", { elementProps });
  if (fieldNames.length < 1) console.log("no fieldNames in config", { elementProps });
  if (!config.label) console.log("no label in config", { elementProps });

  return (
    <EditableSelect
      {...elementProps}
      style={{ fontSize: 11, ...style }}
      fieldIds={fieldIds}
      fieldNames={fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "TOP SECRET", value: fieldIds[0] },
        { label: "SECRET", value: fieldIds[1] },
        { label: "CONFIDENTIAL", value: fieldIds[2] },
        { label: "CUI", value: fieldIds[3] },
        { label: "UNCLASSIFIED", value: fieldIds[4] },
        { label: "TS/SCI", value: fieldIds[5] },
      ]}
    />
  );
};

export const SheetClassification = ({ fieldNames, elementProps }) => {
  const { document } = elementProps?.state;
  const fieldIds = document?.variableFields?.slice(0, 6).map((f) => f.id);
  return (
    <ClassificationSelect
      style={{ width: "100%" }}
      elementProps={elementProps}
      config={{
        label: "Select Classification",
        value: "document_classification_marking",
        fieldIds,
        fieldNames,
      }}
    />
  );
};

export const InformationTypeSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "HIGH", value: config.fieldIds[0] },
        { label: "MODERATE", value: config.fieldIds[1] },
        { label: "LOW", value: config.fieldIds[2] },
      ]}
    />
  );
};

export const FirstCategorySubmissionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "Yes", value: config.fieldIds[0] },
        { label: "No", value: config.fieldIds[1] },
      ]}
    />
  );
};

export const EmassClassificationSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "NIPR", value: config.fieldIds[0] },
        { label: "SIPR", value: config.fieldIds[1] },
        { label: "N/A", value: config.fieldIds[2] },
      ]}
    />
  );
};

// prettier-ignore
export const RmfActionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "Assess & Authorize", value: config.fieldIds[0] },
        { label: "Assess Only - Assess and Incorporate", value: config.fieldIds[1] },
        {
          label: "Assess Only - Assess and Approve for Use and Inherit Process",
          value: config.fieldIds[2],
        },
      ]}
    />
  );
};
