import { usePermissionContext } from "components/Providers/WithPermission";

const useAccessControl = () => {
  const { hasPermission, hasRole } = usePermissionContext();

  const RBAC = (roles: Array<string>) => {
    return roles.some(hasRole);
  };

  const PBAC = (permissions: Array<string>) => {
    return permissions.some(hasPermission);
  };

  return {
    RBAC,
    PBAC,
  };
};

export default useAccessControl;

export enum Roles {
  GlobalAdmin = "GLOBAL_ADMINISTRATOR",
  CsarAdvisor = "CSAR_ADVISOR",
  OrganizationAdmin = "ORGANIZATION_ADMINISTRATOR",
  WorkflowAdmin = "QUESTIONARY_ADMINISTRATOR",
  RiskManager = "RISK_MANAGER",
  ApplicantUser = "APPLICATION_USER",
}

export enum Permissions {
  AssignPermissions = "assign:permissions",

  CreateQuestionary = "create:questionary",
  ReadQuestionary = "read:questionary",
  UpdateQuestionary = "update:questionary",
  DeleteQuestionary = "delete:questionary",

  CreateUsers = "create:users",
  ReadUsers = "read:users",
  UpdateUsers = "update:users",
  DeleteUsers = "delete:users",

  CreateOrganizations = "create:organizations",
  ReadOrganizations = "read:organizations",
  UpdateOrganizations = "update:organizations",
  DeleteOrganizations = "delete:organizations",

  CreateApplications = "create:applications",
  ReadApplications = "read:applications",
  UpdateApplications = "update:applications",
  DeleteApplications = "delete:applications",

  CreateCyberPlans = "create:cyberplans",
  ReadCyberPlans = "read:cyberplans",
  UpdateCyberPlans = "update:cyberplans",
  DeleteCyberPlans = "delete:cyberplans",

  CreateSecFindings = "create:secfindings",
  ReadSecFindings = "read:secfindings",
  UpdateSecFindings = "update:secfindings",
  DeleteSecFindings = "delete:secfindings",

  CreateDelierables = "create:deliverables",
  ReadDelierables = "read:deliverables",
  UpdateDelierables = "update:deliverables",
  DeleteDelierables = "delete:deliverables",

  CreateAppComments = "create:appcomments",
  ReadAppComments = "read:appcomments",
  UpdateAppComments = "update:appcomments",
  DeleteAppComments = "delete:appcomments",

  ReadCheckpoints = "read:checkpoints",
  CreateCheckpoints = "create:checkpoints",
  UpdateCheckpoints = "update:checkpoints",
  DeleteCheckpoints = "delete:checkpoints",

  ReadOrganization = "read:organization",
  UpdateOrganization = "update:organization",

  CreateApplication = "create:application",
  ReadApplication = "read:application",
  UpdateApplication = "update:application",

  ReadApplicationCheckpoints = "read:application_checkpoints",
  CreateApplicationCheckpoints = "create:application_checkpoints",
  UpdateApplicationCheckpoints = "update:application_checkpoints",
  DeleteApplicationCheckpoints = "delete:application_checkpoints",
}
