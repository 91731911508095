import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P8 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const OMarking = {
    label: "Select Marking",
    value: "overlay_classification_marking",
    fieldIds: [
      fieldId("overlays_marking_top_secret"),
      fieldId("overlays_marking_secret"),
      fieldId("overlays_marking_confidential"),
      fieldId("overlays_marking_cui"),
      fieldId("overlays_marking_unclassified"),
      // document.variableFields[155]?.id,
      // document.variableFields[156]?.id,
      // document.variableFields[157]?.id,
      // document.variableFields[158]?.id,
      // document.variableFields[159]?.id,
    ],
    fieldNames: [
      "overlays_marking_top_secret",
      "overlays_marking_secret",
      "overlays_marking_confidential",
      "overlays_marking_cui",
      "overlays_marking_unclassified",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>10. Overlays</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={OMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  <b>10A. Intelligence Overlay</b>: Does the System process, store, or transmit
                  Intelligence, Surveillance, or Reconnaissance (ISR)?
                </div>
                <div>Ref: (q)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10a_yes"
                      // fieldId={document.variableFields[160]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (Intelligence Overlay is required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10a_no"
                      // fieldId={document.variableFields[161]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  <b>10B. Cross Domain Solution (CDS) Overlay</b>: Will you implement, manage,
                  or maintain a CDS?
                </div>
                <div>Ref: (r)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10b_yes"
                      // fieldId={document.variableFields[162]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (CDS Overlay is required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10b_no"
                      // fieldId={document.variableFields[163]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  <b>10C. Nuclear Command, Control & Communications (NC3) Overlay</b>: Does the
                  System store, process, or transmit NC3 data?
                </div>
                <i>
                  NOTE 12: Use of the NC3 Overlay also requires the implementation of the
                  Intelligence Overlay
                </i>
                <div>Refs: (q) & (s) </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10c_yes"
                      // fieldId={document.variableFields[164]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (NC3 Overlay is required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10c_no"
                      // fieldId={document.variableFields[165]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  <b>10D. Space Platform Overlay</b>: Is the System or (subsystem) a space
                  platform (as defined in Committee on National Security Systems Instruction
                  (CNSSI) 1253F, Attachment 2) and unmanned?
                </div>
                <div>Ref: (t)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10d_yes"
                      // fieldId={document.variableFields[166]?.id}
                    />
                  </span>
                  
                  <span>
                    <b>Yes</b> (Space Platform Overlay is required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10d_no"
                      // fieldId={document.variableFields[167]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  <b>10E. Classified Information Overlay</b>: Does the System store, process,
                  or transmit classified information?
                </div>
                <div>Ref: (u)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10e_yes"
                      // fieldId={document.variableFields[168]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b> (Classified Information Overlay is required)
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10e_no"
                      // fieldId={document.variableFields[169]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div>
                  <b>10F. DoD Functional Mission/Capability Specific Overlay</b>: Is your
                  System required to execute an organizational mission or function-specific
                  capability? (e.g., Financial, Acquisition)
                </div>
                <div>Ref: (v)</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10f_yes"
                      // fieldId={document.variableFields[170]?.id}
                    />
                  </span>
                  <div style={{ width: "100%", marginRight: 5 }}>
                    <b>Yes</b> Specify Overlay
                    <div style={{ width: "100%" }}>
                      <EditableField
                        {...elementProps}
                        fieldName="10f_yes_additional"
                        // fieldId={document.variableFields[171]?.id}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="10f_no"
                      // fieldId={document.variableFields[172]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
