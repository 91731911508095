import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P1 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const classificationNames = [
    "p1_warning_classification_top_secret",
    "p1_warning_classification_secret",
    "p1_warning_classification_confidential",
    "p1_warning_classification_cui",
    "p1_warning_classification_unclassified",
  ];

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const P1WarningMarking = {
    label: "Select Marking",
    value: "p1_warning_marking",
    fieldIds: [
      fieldId("p1_warning_classification_top_secret"),
      fieldId("p1_warning_classification_secret"),
      fieldId("p1_warning_classification_confidential"),
      fieldId("p1_warning_classification_cui"),
      fieldId("p1_warning_classification_unclassified"),
    ],
    fieldNames: [
      "p1_warning_classification_top_secret",
      "p1_warning_classification_secret",
      "p1_warning_classification_confidential",
      "p1_warning_classification_cui",
      "p1_warning_classification_unclassified",
    ],
  };

  return (
    <>
      <div style={{ textAlign: "center", fontSize: 22, padding: "100px 0 200px" }}>
        <b>
          <div>Department of the Air Force (DAF)</div>
          <div>Risk Management Framework (RMF) Information Technology (IT)</div>
          <div>Categorization and Selection Checklist (ITCSC)</div>
        </b>
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "80%",
            textAlign: "center",
            border: "1px solid black",
            padding: 5,
          }}
        >
          <b style={{ fontSize: 14 }}>
            Controlled Unclassified Information (CUI) Designation Indicator Ref: (b)
          </b>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Controlled by (Office): </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="collected_by_office"
                // fieldId={document.variableFields[5].id}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>CUI Category Ref: (c): </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="cui_category_ref"
                // fieldId={document.variableFields[6].id}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>
              Distribution/Dissemination Control: (c):
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="distribution_dissemination_control"
                // fieldId={document.variableFields[7].id}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>POC: (c): </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="poc"
                // fieldId={document.variableFields[8].id}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "80%",
            textAlign: "center",
            border: "1px solid black",
            padding: 5,
          }}
        >
          <b style={{ fontSize: 14 }}>CUI Warning Box for Classified Material Ref: (b)</b>
          <div>
            <div style={{ textAlign: "left" }}>
              <div style={{ display: "flex" }}>
                This content is classified at the{" "}
                <ClassificationSelect
                  elementProps={elementProps}
                  config={P1WarningMarking}
                  style={{ width: "150px", margin: "0 4px" }}
                />{" "}
                level and may contain elements
              </div>
              <div>
                of controlled unclassified information (CUI), unclassified, or information
                classified at a lower level than the overall classification displayed. This
                content shall not be used as a source of derivative classification; refer
                instead to [cite specific reference, where possible, or state “the applicable
                classification guide(s)”]. It must be reviewed for both Classified National
                Security Information and CUI in accordance with DoD Instruction 5230.09 Ref:
                (d) prior to public release.
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Contact: </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="contact"
                // fieldId={document.variableFields[14].id}
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "80%",
            textAlign: "center",
            border: "1px solid black",
            padding: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Classified By: </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="classified_by"
                // fieldId={document.variableFields[15].id}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Derived From: </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldName="derived_from"
                // fieldId={document.variableFields[16].id}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ whiteSpace: "nowrap", paddingTop: 4 }}>Declassify On: </div>
            <div
              style={{
                width: "100%",
                marginLeft: 4,
                marginBottom: 2,
              }}
            >
              <EditableField
                {...elementProps}
                fieldType="FULFILL_DATE"
                fieldName="declassified_on"
                // fieldId={document.variableFields[17].id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
