import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect, InformationTypeSelect } from "..";

const informationSelectStyle = {
  width: "100%",
  border: "none",
};

export const P17 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const confidentialityConfig = {
    label: "Select One",
    value: "confidentiality_information_type",
    fieldIds: [
      fieldId("cloud_impact_level_final_system_categorization_confidentiality_high"),
      fieldId("cloud_impact_level_final_system_categorization_confidentiality_moderate"),
      fieldId("cloud_impact_level_final_system_categorization_confidentiality_low"),
      // document.variableFields[507]?.id,
      // document.variableFields[508]?.id,
      // document.variableFields[509]?.id,
    ],
    fieldNames: [
      "cloud_impact_level_final_system_categorization_confidentiality_high",
      "cloud_impact_level_final_system_categorization_confidentiality_moderate",
      "cloud_impact_level_final_system_categorization_confidentiality_low",
    ],
  };

  const integrityConfig = {
    label: "Select One",
    value: "integrity_information_type",
    fieldIds: [
      fieldId("cloud_impact_level_final_system_categorization_integrity_high"),
      fieldId("cloud_impact_level_final_system_categorization_integrity_moderate"),
      fieldId("cloud_impact_level_final_system_categorization_integrity_low"),
      // document.variableFields[510]?.id,
      // document.variableFields[511]?.id,
      // document.variableFields[512]?.id,
    ],
    fieldNames: [
      "cloud_impact_level_final_system_categorization_integrity_high",
      "cloud_impact_level_final_system_categorization_integrity_moderate",
      "cloud_impact_level_final_system_categorization_integrity_low",
    ],
  };

  const availabilityConfig = {
    label: "Select One",
    value: "availability_information_type",
    fieldIds: [
      fieldId("cloud_impact_level_final_system_categorization_availability_high"),
      fieldId("cloud_impact_level_final_system_categorization_availability_moderate"),
      fieldId("cloud_impact_level_final_system_categorization_availability_low"),
      // document.variableFields[513]?.id,
      // document.variableFields[514]?.id,
      // document.variableFields[515]?.id,
    ],
    fieldNames: [
      "cloud_impact_level_final_system_categorization_availability_high",
      "cloud_impact_level_final_system_categorization_availability_moderate",
      "cloud_impact_level_final_system_categorization_availability_low",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1, padding: "10px" }}>
              <b style={{ fontSize: 14 }}>
                Cloud Impact Level (7) or Complete Final System Categorization (14)
              </b>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  backgroundColor: "white",
                  borderTop: "1px solid black",
                  padding: "5px 0",
                }}
              >
                <b style={{ fontSize: 12 }}>Cloud Impact Level</b>
                <div style={{ textAlign: "left" }}>
                  {/* <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_1"
                        // fieldId={document.variableFields[501]?.id}
                      />
                    </span>
                    <span>1</span>
                  </div> */}
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_2"
                        // fieldId={document.variableFields[502]?.id}
                      />
                    </span>
                    <span>2</span>
                  </div>
                  {/* <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_3"
                        // fieldId={document.variableFields[503]?.id}
                      />
                    </span>
                    <span>3</span>
                  </div> */}
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_4"
                        // fieldId={document.variableFields[504]?.id}
                      />
                    </span>
                    <span>4</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_5"
                        // fieldId={document.variableFields[505]?.id}
                      />
                    </span>
                    <span>5</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_6"
                        // fieldId={document.variableFields[505]?.id}
                      />
                    </span>
                    <span>6</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="cloud_impact_level_na"
                        // fieldId={document.variableFields[506]?.id}
                      />
                    </span>
                    <span>N/A</span>
                  </div>
                </div>
              </div>

              <div style={{ flex: 5, display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "lightgrey",
                    border: "1px solid black",
                    borderRight: "none",
                  }}
                >
                  <div style={{ flex: 1.5, borderRight: "1px solid black" }}>
                    Information Types
                  </div>
                  <div style={{ flex: 1, borderRight: "1px solid black" }}>
                    Confidentiality
                  </div>
                  <div style={{ flex: 1, borderRight: "1px solid black" }}>Integrity</div>
                  <div style={{ flex: 1 }}>Availability</div>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    border: "1px solid black",
                    borderTop: "none",
                    borderRight: "none",
                  }}
                >
                  <div style={{ flex: 1.5, borderRight: "1px solid black" }}>
                    <div
                      style={{ padding: "10px 5px", backgroundColor: "white", height: "100%" }}
                    >
                      <b>FINAL SYSTEM CATEGORIZATION</b>
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      borderRight: "1px solid black",
                    }}
                  >
                    <InformationTypeSelect
                      elementProps={elementProps}
                      config={confidentialityConfig}
                      style={informationSelectStyle}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      borderRight: "1px solid black",
                    }}
                  >
                    <InformationTypeSelect
                      elementProps={elementProps}
                      config={integrityConfig}
                      style={informationSelectStyle}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      borderRight: "none",
                    }}
                  >
                    <InformationTypeSelect
                      elementProps={elementProps}
                      config={availabilityConfig}
                      style={informationSelectStyle}
                    />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    border: "1px solid black",
                    borderTop: "none",
                    borderRight: "none",
                  }}
                >
                  <div style={{ flex: 2, borderRight: "1px solid black" }}>
                    <div
                      style={{ padding: "10px 5px", backgroundColor: "white", height: "100%" }}
                    >
                      <b>JUSTIFICATION</b>
                    </div>
                  </div>
                  <div style={{ flex: 4 }}>
                    <EditableField
                      borderless
                      {...elementProps}
                      fieldName="cloud_impact_level_justification_confidentiality_integrity_availability"
                      // fieldId={document.variableFields[516]?.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>Approval</b>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Program Manager/ISO:</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_pm_iso_text"
                    // fieldId={document.variableFields[517]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Organization:</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_pm_iso_organization"
                    // fieldId={document.variableFields[518]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>E-mail:</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_pm_iso_email"
                    // fieldId={document.variableFields[519]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Phone (Commercial):</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  borderRight: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_pm_iso_phone_commercial"
                    // fieldId={document.variableFields[520]?.id}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Phone (DSN):</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_pm_iso_phone_dsn"
                    // fieldId={document.variableFields[521]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <div>
                  <b>Program Manager/ISO </b>
                  <span> (Digital Signature):</span>
                </div>
                <span></span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                {/* <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldId={document.variableFields[500]?.id}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "20px",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>AO/AODR:</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_ao_aodr_text"
                    // fieldId={document.variableFields[522]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Organization</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_ao_aodr_organization"
                    // fieldId={document.variableFields[523]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>E-mail:</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_ao_aodr_email"
                    // fieldId={document.variableFields[524]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Phone (Commercial):</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  borderRight: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_ao_aodr_phone_commercial"
                    // fieldId={document.variableFields[525]?.id}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 0.2,
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <span>Phone (DSN):</span>
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldName="cloud_impact_level_approval_ao_aodr_phone_dsn"
                    // fieldId={document.variableFields[526]?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                flex: 0.3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                borderRight: "1px solid black",
                height: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "2px 5px",
                }}
              >
                <div>
                  <b>AO/AODR:</b>
                  <span> (Digital Signature)</span>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                {/* <div
                  style={{
                    width: "100%",
                    height: "25px",
                    fontSize: 14,
                  }}
                >
                  <EditableField
                    borderless
                    {...elementProps}
                    fieldId={document.variableFields[500]?.id}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
