import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import {
  ClassificationSelect,
  EmassClassificationSelect,
  FirstCategorySubmissionSelect,
  RmfActionSelect,
} from "..";

export const P2 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const SSIMarking = {
    label: "Select Marking",
    value: "ssi_classification_marking",
    fieldIds: [
      fieldId("sii_marking_top_secret"),
      fieldId("sii_marking_secret"),
      fieldId("sii_marking_confidential"),
      fieldId("sii_marking_cui"),
      fieldId("sii_marking_unclassified"),
    ],
    fieldNames: [
      "sii_marking_top_secret",
      "sii_marking_secret",
      "sii_marking_confidential",
      "sii_marking_cui",
      "sii_marking_unclassified",
    ],
  };

  const AOABMarking = {
    label: "Select Marking",
    value: "sos_classification_marking",
    fieldIds: [
      fieldId("ao_ab_marking_top_secret"),
      fieldId("ao_ab_marking_secret"),
      fieldId("ao_ab_marking_confidential"),
      fieldId("ao_ab_marking_cui"),
      fieldId("ao_ab_marking_unclassified"),
    ],
    fieldNames: [
      "ao_ab_marking_top_secret",
      "ao_ab_marking_secret",
      "ao_ab_marking_confidential",
      "ao_ab_marking_cui",
      "ao_ab_marking_unclassified",
    ],
  };

  // prettier-ignore
  const FirstCategorySubmissionConfig = {
    label: "Select One",
    value: "first_categorization_submission",
    fieldIds: [
      fieldId('first_categorization_submission_yes'),
      fieldId('first_categorization_submission_no'),
    ],
    fieldNames: [
      "first_categorization_submission_yes",
      "first_categorization_submission_no",
    ]
  };

  const EmassClassificationConfig = {
    label: "Select One",
    value: "emass_classification",
    fieldIds: [
      fieldId("emass_classification_nipr"),
      fieldId("emass_classification_sipr"),
      fieldId("emass_classification_na"),
    ],
    fieldNames: [
      "emass_classification_nipr",
      "emass_classification_sipr",
      "emass_classification_na",
    ],
  };

  const RmfActionConfig = {
    label: "Select One",
    value: "rmf_action",
    fieldIds: [
      fieldId("rmf_action_assess_and_authorize"),
      fieldId("rmf_action_assess_and_incorporate"),
      fieldId("rmf_action_assess_and_approve"),
    ],
    fieldNames: [
      "rmf_action_assess_and_authorize",
      "rmf_action_assess_and_incorporate",
      "rmf_action_assess_and_approve",
    ],
  };

  return (
    <>
      <div style={{ textAlign: "center", fontSize: 18, padding: "50px 0 50px" }}>
        <b>
          <div>Department of the Air Force (DAF) Risk Management Framework (RMF)</div>
          <div>Information Technology (IT) System Categorization and Selection Checklist</div>
        </b>
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderBottom: "none",
            }}
          >
            <div>
              <b style={{ fontSize: 14 }}>1. System Identification Information</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={SSIMarking} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>System Name:</b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="system_name"
                  // fieldId={document.variableFields[23].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>System Acronym:</b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="system_acronym"
                  // fieldId={document.variableFields[24].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>Version:</b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="version"
                  // fieldId={document.variableFields[25].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>
              First Categorization Submission: (Choose One)
            </b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  height: "100%",
                  borderLeft: "1px solid black",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <FirstCategorySubmissionSelect
                    style={{ width: "100%", border: "none" }}
                    elementProps={elementProps}
                    config={FirstCategorySubmissionConfig}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>
              Enterprise Mission Assurance Support Service (eMASS) ID:
            </b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="emass_id"
                  // fieldId={document.variableFields[28].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderLeft: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>
              eMASS Classification: (Choose One)
            </b>
            <div style={{ flex: 1.25 }}>
              <div style={{ textAlign: "left", borderLeft: "1px solid black" }}>
                <EmassClassificationSelect
                  style={{ width: "100%", border: "none" }}
                  elementProps={elementProps}
                  config={EmassClassificationConfig}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>
              IT Investment Portfolio Suite (ITIPS) ID:
            </b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="it_investment_portfolio"
                  // fieldId={document.variableFields[32].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>
              DoD Information Technology Portfolio Repository (DITPR) ID:
            </b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="ditpr"
                  // fieldId={document.variableFields[33].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>AFID:</b>
            <div style={{ flex: 1.25 }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderLeft: "1px solid black",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="afid"
                  // fieldId={document.variableFields[34].id}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ flex: 1, paddingLeft: 5, textAlign: "left" }}>
              RMF Action: (Choose One)
            </b>
            <div style={{ flex: 1.25, borderLeft: "1px solid black" }}>
              <RmfActionSelect
                style={{ width: "100%", border: "none" }}
                elementProps={elementProps}
                config={RmfActionConfig}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <i style={{ paddingLeft: 5, textAlign: "left" }}>
              Note 1: In cases where no PII/PHI is present, the DAF ITCSC will serve as a
              conclusive determination that privacy requirements do not apply to the system.
              Note 2: Systems that do contain PII/PHI will need to complete a Privacy Impact
              Assessment (PIA) in conjunction with an organizational privacy subject matter
              expert. Ref: (e)
            </i>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>
                2. Authorizing Official (AO) & Authorization Boundary (Select Only One)
              </b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={AOABMarking} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.25,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_af_offensive_cyber_operations_cyberspace_weapons_systems"
                    // fieldId={document.variableFields[43].id}
                  />
                </div>
                <div>Air Force Offensive Cyber Operations & Cyberspace Weapons Systems</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_af_operational_and_evaluation"
                    // fieldId={document.variableFields[44].id}
                  />
                </div>
                <div>Air Force Operational and Evaluation (AFOTEC)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_af_special_operations_command"
                    // fieldId={document.variableFields[45].id}
                  />
                </div>
                <div>Air Force Special Operations Command (AFSOC)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_af_sustainment_center"
                    // fieldId={document.variableFields[46].id}
                  />
                </div>
                <div>Air Force Sustainment Center (AFSC) Software Enterprise (SWENT)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_af_weather_update_systems"
                    // fieldId={document.variableFields[47].id}
                  />
                </div>
                <div>Air Force Weather Weapon System (AFWWS)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_aircraft"
                    // fieldId={document.variableFields[48].id}
                  />
                </div>
                <div>Aircraft</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_civil_engineering_control_systems"
                    // fieldId={document.variableFields[49].id}
                  />
                </div>
                <div>Civil Engineering (CE) Control Systems (CS) </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_ce_infomation_technology_and_ce_platforms"
                    // fieldId={document.variableFields[50].id}
                  />
                </div>
                <div>CE Information Technology (IT) and CE Platforms</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_command_and_control"
                    // fieldId={document.variableFields[51].id}
                  />
                </div>
                <div>Command and Control (C2)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.25,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_cyberspace_innovation"
                    // fieldId={document.variableFields[52].id}
                  />
                </div>
                <div>Cyberspace Innovation</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_daf_cloud_and_dev_sec_ops"
                    // fieldId={document.variableFields[53].id}
                  />
                </div>
                <div>DAF Cloud and DevSecOps</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_daf_enterprise"
                    // fieldId={document.variableFields[54].id}
                  />
                </div>
                <div>DAF Enterprise</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_dod_cyber_crime_center"
                    // fieldId={document.variableFields[55].id}
                  />
                </div>
                <div>DoD Cyber Crime Center (DC3)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_dod_mission_partner"
                    // fieldId={document.variableFields[56].id}
                  />
                </div>
                <div>DoD Mission Partner Environment (MPE)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_depot_support_systems"
                    // fieldId={document.variableFields[57].id}
                  />
                </div>
                <div>Depot Support Systems (DSS)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_development_test_and_evaluation"
                    // fieldId={document.variableFields[58].id}
                  />
                </div>
                <div>Developmental Test and Evaluation (DT&E)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_finance"
                    // fieldId={document.variableFields[59].id}
                  />
                </div>
                <div>Finance</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_force_development"
                    // fieldId={document.variableFields[60].id}
                  />
                </div>
                <div>Force Development</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_headquarters_air_force"
                    // fieldId={document.variableFields[61].id}
                  />
                </div>
                <div>Headquarters Air Force (HAF)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.25,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_human_resources_management"
                    // fieldId={document.variableFields[62].id}
                  />
                </div>
                <div>Human Resource Management (HRM)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_industrial_depot_maintenance"
                    // fieldId={document.variableFields[63].id}
                  />
                </div>
                <div>Industrial Depot Maintenance (IDM)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_logistics"
                    // fieldId={document.variableFields[64].id}
                  />
                </div>
                <div>Logistics</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_operational_test_and_training_infrastructure"
                    // fieldId={document.variableFields[65].id}
                  />
                </div>
                <div>Operational Test and Training Infrastructure (OTI)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_rapid_cyber_acquisition"
                    // fieldId={document.variableFields[66].id}
                  />
                </div>
                <div>Rapid Cyber Acquisition (RCA)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_science_and_technology"
                    // fieldId={document.variableFields[67].id}
                  />
                </div>
                <div>Science & Technology (S&T)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_security_forces_force_protection"
                    // fieldId={document.variableFields[68].id}
                  />
                </div>
                <div>Security Forces / Force Protection (A4S)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_special_access_programs"
                    // fieldId={document.variableFields[69].id}
                  />
                </div>
                <div>Special Access Program (SAP)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_strategic_systems"
                    // fieldId={document.variableFields[70].id}
                  />
                </div>
                <div>Strategic Systems</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.25,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_us_space_force_in_europe_usafe"
                    // fieldId={document.variableFields[71].id}
                  />
                </div>
                <div>US Air Forces in Europe (USAFE)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_us_space_force_ussf"
                    // fieldId={document.variableFields[71].id}
                  />
                </div>
                <div>US Space Force (USSF)</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_us_space_force_space_systems_command_aerospace_systems_ssc"
                    // fieldId={document.variableFields[71].id}
                  />
                </div>
                <div>
                  USSF Space Systems Command Aerospace (SSC) Research, Development, Testing,
                  and Engineering Systems
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_weapons"
                    // fieldId={document.variableFields[72].id}
                  />
                </div>
                <div>Weapons</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="aoab_other_17-101_pick_one"
                    // fieldId={document.variableFields[73].id}
                  />
                  <span style={{ marginLeft: 5, marginRight: 5 }}>
                    Other (See Air Force Instruction 17-101)
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      width: "150px",
                      marginLeft: "15px",
                    }}
                  >
                    <EditableField
                      {...elementProps}
                      fieldName="aoab_other_17-101_fulfill_text"
                      // fieldId={document.variableFields[74].id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <i style={{ paddingLeft: 5, textAlign: "left" }}>
              Note 3: A listing of AOs and AO boundary descriptions can be found on the DAF
              Risk Management Framework (RMF) Knowledge Service at{" "}
              <a href="https://rmfks.osd.mil/rmf/collaboration/Component%20Workspaces/AirForce/Pages/default.aspx">
                https://rmfks.osd.mil/rmf/collaboration/Component%20Workspaces/AirForce/Pages/default.aspx
              </a>{" "}
              (Navigate to Shared Documents/AO folder)
            </i>
          </div>
        </div>
      </div>
    </>
  );
};
