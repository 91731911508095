import { PIAFooter, PIAHeader, PIAPages } from "./Documents/PIA";
import { ITCSCv83Header, ITCSCv83Pages, ITCSCv83Footer } from "./Documents/ITCSCv8.3";
import { ITCSCv84Header, ITCSCv84Pages, ITCSCv84Footer } from "./Documents/ITCSCv8.4";
import { ITCSCv902Header, ITCSCv902Pages, ITCSCv902Footer } from "./Documents/ITCSCv9.0.2";
import { CTPHeader, CTPPages, CTPFooter } from "./Documents/CTP/index";
import { AMHeader, AMPages, AMFooter } from "./Documents/AM/index";
import { AODBHeader, AODBPages, AODBFooter } from "./Documents/AODB/index";
import { PCILHeader, PCILPages, PCILFooter } from "./Documents/PCIL/index";

const documents = [
  {
    id: "privacy_impact_assessment",
    header: PIAHeader,
    forms: PIAPages,
    footer: PIAFooter,
  },
  // {
  //   id: "information_technology_categorization_and_selection_checklist_8.3",
  //   header: ITCSCv83Header,
  //   forms: ITCSCv83Pages,
  //   footer: ITCSCv83Footer,
  // },
  {
    id: "information_technology_categorization_and_selection_checklist_8.4",
    header: ITCSCv84Header,
    forms: ITCSCv84Pages,
    footer: ITCSCv84Footer,
  },
  {
    id: "information_technology_categorization_and_selection_checklist_9.0.2",
    header: ITCSCv902Header,
    forms: ITCSCv902Pages,
    footer: ITCSCv902Footer,
  },
  {
    id: "pii_confidentiality_impact_level_categorization",
    header: PCILHeader,
    forms: PCILPages,
    footer: PCILFooter,
  },
  {
    id: "certification_test_plan",
    header: CTPHeader,
    forms: CTPPages,
    footer: CTPFooter,
  },
  {
    id: "authorization_memo",
    header: AMHeader,
    forms: AMPages,
    footer: AMFooter,
  },
  {
    id: "ao_determination_brief",
    header: AODBHeader,
    forms: AODBPages,
    footer: AODBFooter,
  },
];

export default documents;
