import { Link, useParams } from "react-router-dom";
import { Button, Card } from "antd";

const PageComplete = ({ onLastPage }: { onLastPage: boolean }) => {
  const { applicationId, moduleId } = useParams();

  if (!onLastPage) return <></>;
  return (
    <>
      <Card className="shadowed-static">
        <p>You have reached the end of the category</p>
        <Button style={{ marginRight: 16 }}>
          <Link to={`/workflow/${applicationId}`} state={{ key: "applicationDashboard" }}>
            Go to Dashboard
          </Link>
        </Button>
        <Button type="primary">
          <Link to={`/workflow/${applicationId}/${moduleId}`}>Back to Module</Link>
        </Button>
      </Card>
      <br />
    </>
  );
};

export default PageComplete;
