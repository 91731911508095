import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { AlignedEditableCheckbox, AlignedEditableRadio } from "..";
import { Row, Col } from "antd";
import DodSeal from "../Assets/dod_seal.png";

export const P1 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        className="ao-document"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 40,
        }}
      >
        <div
          className="header"
          style={{
            width: "100%",
            height: 80,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div className="seal-container" style={{ height: 50, width: 60 }}>
            <img
              src={DodSeal}
              alt="DoD Seal"
              style={{ width: "100%", height: "100%", aspectRatio: "2/3" }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <h1
              style={{
                color: "#010379 !important",
                fontWeight: "bolder !important",
                width: "100%",
                paddingLeft: "15%",
              }}
            >
              DEPARTMENT OF THE AIR FORCE
            </h1>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: "20px",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              textAlign: "right",
              width: "30%",
              marginBottom: 12,
            }}
          >
            <FieldWrapper>
              <EditableField {...elementProps} fieldName="date_m_d_y" />
            </FieldWrapper>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: 35,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: "bold" }}>MEMORANDUM FOR</p>
          <div style={{ marginLeft: 4 }}>
            <FieldWrapper>
              <EditableField {...elementProps} fieldName="1_memo_for_program" />
            </FieldWrapper>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: 35,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: "bold" }}>FROM: AO </p>
          <div style={{ marginLeft: 4 }}>
            <FieldWrapper>
              <EditableField {...elementProps} fieldName="1_from_ao_boundary" />
            </FieldWrapper>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: 40,
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ display: "flex", height: 40, flexDirection: "row", width: "100%" }}>
            <p style={{ fontWeight: "bolder", paddingRight: 4 }}>SUBJECT: </p>
            <FieldWrapper>
              <EditableField {...elementProps} fieldName="1_subject_authorization_type" />
            </FieldWrapper>
            <p style={{ paddingLeft: 2, minWidth: 40 }}>for the</p>
            <FieldWrapper>
              <EditableField {...elementProps} fieldName="1_memo_for_program" />
            </FieldWrapper>
            <p style={{ paddingLeft: 2 }}>,</p>
          </div>
        </div>

        <div
          style={{
            minWidth: "100%",
            height: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginBottom: 24,
          }}
        >
          <div style={{ width: 59 }}></div>
          <div
            style={{
              display: "flex",
              height: 40,
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <FieldWrapper style={{ paddingRight: 2 }}>
              <EditableField {...elementProps} fieldName="1_subject_system_name" />
            </FieldWrapper>
            <p style={{ paddingRight: 2 }}>. Authorization Termination Date (ATD):</p>
            <FieldWrapper>
              <EditableField
                {...elementProps}
                fieldType="FULFILL_DATE"
                fieldName="1_subject_atd_date_m_d_y"
              />
            </FieldWrapper>
            <p>.</p>
          </div>
        </div>

        <div
          className="list-container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <ol>
            <li>
              <div className="list-item-container">
                <p>(U) As the appointed Air Force Authorizing Official (AO) for </p>
                <div className="editable-wrapper">
                  <EditableField {...elementProps} fieldName="1_from_ao_boundary" />
                </div>
                <p>, I have determined </p>
                <div className="editable-wrapper">
                  <EditableField {...elementProps} fieldName="1_subject_system_name" />
                </div>
                <p>has a residual risk level of </p>
                <div className="editable-wrapper">
                  <EditableField {...elementProps} fieldName="1_risk_level" />
                </div>
                <p> . Conditions for this </p>
                <div className="editable-wrapper">
                  <EditableField {...elementProps} fieldName="1_subject_authorization_type" />
                </div>
                <p>are provided in Attachment 1.</p>
              </div>
            </li>

            <li>
              <div className="list-item-container">
                <p>(U) This Memo serves as the system authorization for</p>
                <div className="editable-wrapper">
                  <EditableField {...elementProps} fieldName="1_memo_for_program" />
                </div>
                <p>. Any deviations and or changes from the submitted body of</p>
                <p>
                  evidence shall be documented and coordinated with the Cyber Risk Assessor
                  (CRA) as part of the continued authorization process.
                </p>
              </div>
            </li>

            <li>
              <p>
                (U) This determination is based on the assessment of the security posture,
                identified risks, system-specific requirements, proposed and agreed-to
                mitigations, and all supporting documentation evaluated in accordance with AF
                and DoD guidance. Key documents from the Body of Evidence (BOE) reviewed are
                identified in Attachment 2.
              </p>
            </li>
            <li>
              <p>
                (U) This authorization is the formal declaration that appropriate system
                security measures and/or countermeasures for the identified risks have been
                properly implemented and that a satisfactory level of security is present for
                operations given the identified Risks and Tolerances. A Plan of Action and
                Milestones (POA&M) is required to be maintained and reviewed, its progress
                reported in accordance with the continued assessment and authorization process
                as agreed.
              </p>
            </li>

            <li>
              <div className="list-item-container">
                <p>
                  (U) I maintain the authority to revoke this ATO based on lack of due
                  diligence, non-compliance with DoD and Air Force policies, and other
                </p>

                <p>security-related infractions. Contact email:</p>
                <div className="editable-wrapper">
                  <EditableField {...elementProps} fieldName="1_5_cra_email_poc" />
                </div>
              </div>
            </li>
          </ol>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div>
            <br />
            <p style={{ marginBottom: 3 }}>Authorizing Official</p>
            <div className="editable-wrapper">
              <EditableField {...elementProps} fieldName="1_sig_ao_boundary" />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <div className="attachment-container">
            <p style={{ marginBottom: 6, marginLeft: 4 }}>Attachments:</p>
            <ol>
              <li>
                <div className="attachment-wrapper">
                  <EditableField {...elementProps} fieldName="1_attachments_condition" />
                </div>
              </li>
              <li>
                <div className="attachment-wrapper">
                  <EditableField
                    {...elementProps}
                    fieldName="1_attachments_body_of_evidence"
                  />
                </div>
              </li>
              <li>
                <div className="attachment-wrapper">
                  <EditableField
                    {...elementProps}
                    fieldName="1_attachments_plan_of_action_milestones"
                  />
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export const FieldWrapper = ({ height, children, styles }) => {
  return (
    <div
      style={{
        ...styles,
        background: "#AAD3EE",
        width: 200,
        maxWidth: "100%",
        border: "1px solid #000000",
        height: height,
        marginBottom: 12,
      }}
    >
      {children}
    </div>
  );
};
