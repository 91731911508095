import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Space } from "antd";

export const P11 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props?.pageNumber
  };

  return (
    <>
      <div className="page-table">
        <table border={1}>
          <colgroup>
            <col span={1} width={'32%'} />
            <col span={1} width={'32%'} />
            <col span={1} width={'35%'} />
          </colgroup>

          <thead>
            <tr>
              <th className="title" colSpan={3} style={{ background: 'none', textAlign: 'left', paddingLeft: '10px' }}>
                13E. Privacy Factors (Select One Per Factor)
              </th>
            </tr>
          </thead>
          <tr>
            <td style={{ verticalAlign: 'top', borderBottom: 'none', paddingLeft: '10px' }} >
              <b>Privacy Factor 1 (Identifiability):</b><br />
              Organizations should evaluate how<br />
              easily PII can be used to identify<br />
              specific individuals.<br />
            </td>
            <td style={{ verticalAlign: 'top', borderBottom: 'none', paddingLeft: '10px' }} >
              <b>Privacy Factor 2 (Quantity of PII):</b><br />
              Organizations may also choose to<br />
              consider how many individuals are<br />
              identified in the information (e.g.,<br />
              number of records).
            </td>
            <td style={{ verticalAlign: 'top', borderBottom: 'none', paddingLeft: '10px' }} >
              <b>Privacy Factor 3 (Data Field Sensitivity):</b><br />
              Organizations should evaluate the sensitivity of<br />
              each individual PII data field, as well as the<br />
              sensitivity of the PII data fields together.
            </td>
          </tr>
          <tr>
            <td style={{ alignItems: 'center', borderTop: 'none' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_1_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_1_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_1_low" label="Low" />
              </Space>
            </td>
            <td style={{ alignItems: 'center', borderTop: 'none' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_2_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_2_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_2_low" label="Low" />
              </Space>
            </td>
            <td style={{ alignItems: 'center', borderTop: 'none' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_3_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_3_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_3_low" label="Low" />
              </Space>
            </td>
          </tr>

          <tr>
            <td style={{ verticalAlign: 'top', borderBottom: 'none', paddingLeft: '10px' }} >
              <b>Privacy Factor 4 (Context of Use):</b><br />
              Organizations should access the<br />
              purpose for which PII is collected,<br />
              stored, used, processed, disclosed, or<br />
              disseminated.
            </td>
            <td style={{ verticalAlign: 'top', borderBottom: 'none', paddingLeft: '10px' }} >
              <b>Privacy Factor 5 (Obligation to<br />
                Protect Confidentiality):</b> Evaluate the<br />
              requirements (e.g., laws, mandates, and<br />
              regulations) to protect
            </td>
            <td style={{ verticalAlign: 'top', borderBottom: 'none', paddingLeft: '10px' }} >
              <b>Privacy Factor 6 (Access and Location):</b><br />
              Organizations may choose to take into<br />
              consideration the nature of authorized access<br />
              to PII.
            </td>
          </tr>
          <tr>
            <td style={{ alignItems: 'center', borderTop: 'none' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_4_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_4_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_4_low" label="Low" />
              </Space>
            </td>
            <td style={{ alignItems: 'center', borderTop: 'none' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_5_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_5_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_5_low" label="Low" />
              </Space>
            </td>
            <td style={{ alignItems: 'center', borderTop: 'none' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_6_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_6_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13e_privacy_factor_6_low" label="Low" />
              </Space>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'top', paddingLeft: '10px' }} colSpan={2}>
              <b>13F. Determine PII Confidentiality Impact Level (PCIL)</b><br />
              <i>
                Note 16: The confidentiality of PII should be protected based on its impact<br />
                level. The PCIL (Low, Moderate, or High) indicates the potential harm that<br />
                could result to the subject individuals and/or the organization if PII were<br />
                inappropriately accessed, used, or disclosed.<br />
                Note 17: Apply applicable Privacy Overlay.
              </i>
            </td>
            <td style={{ alignItems: 'center', verticalAlign: 'top', paddingTop: '4px' }}>
              <Space direction="vertical" style={{ marginLeft: '20%' }}>
                <EditableRadio {...elementProps} fieldName="13f_determine_pii_high" label="High" />
                <EditableRadio {...elementProps} fieldName="13f_determine_pii_moderate" label="Moderate" />
                <EditableRadio {...elementProps} fieldName="13f_determine_pii_low" label="Low" />
              </Space>
            </td>
          </tr>

        </table>
      </div>
    </>
  );
};
