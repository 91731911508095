import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P11 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.55,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  12F. If there is a loss of confidentiality, what type of adverse effect will
                  it have on individuals?
                </b>
                <div>Ref: (w)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.45,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12f_limited"
                      // fieldId={document.variableFields[242]?.id}
                    />
                  </span>
                  <span>
                    <b>Limited</b>/Minor degradation, damage, loss, or harm
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12f_serious"
                      // fieldId={document.variableFields[243]?.id}
                    />
                  </span>
                  <span>
                    <b>Serious</b>/Significant degradation, damage, loss, or harm
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12f_severe"
                      // fieldId={document.variableFields[244]?.id}
                    />
                  </span>
                  <span>
                    <b>Severe</b>Catastrophic degradation, damage, loss, or harm
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: "0 5px",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b>
              12G. Provide detailed example(s) of the potential harm to an individual or
              organization if the PII were to be compromised. (Example: The system contains
              someone’s SSN, which could be used to commit identity fraud.)
            </b>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderTop: "none",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "225px",
                fontSize: 14,
              }}
            >
              <EditableField
                borderless
                {...elementProps}
                fieldName="12g_text"
                // fieldId={document.variableFields[245]?.id}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.55,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  12H. Does your system retrieve information by a personal identifier (i.e.,
                  SSN, Name, DOB, etc.)?
                </b>
                <div>Refs: (i), (j), (k), & (l)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.45,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12h_yes"
                      // fieldId={document.variableFields[246]?.id}
                    />
                  </span>
                  <div style={{ width: "100%", marginRight: 5 }}>
                    <b>Yes</b> Provide SORN Number
                    <div style={{ width: "100%" }}>
                      <EditableField
                        {...elementProps}
                        fieldName="12h_yes_additional"
                        // fieldId={document.variableFields[247]?.id}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12h_no"
                      // fieldId={document.variableFields[248]?.id}
                    />
                  </span>
                  <span>
                    <b>No</b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.55,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>12I. Determine PII Confidentiality Impact Level</b>
                <i>
                  NOTE 15: Assessment of Impact Level should consider aggregation of all
                  privacy factors (Questions 12D - 12G).
                </i>
                <div>Ref: (w)</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.45,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12i_high"
                      // fieldId={document.variableFields[249]?.id}
                    />
                  </span>
                  <span>
                    <b>High</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12i_moderate"
                      // fieldId={document.variableFields[250]?.id}
                    />
                  </span>
                  <span>
                    <b>Moderate</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="12i_low"
                      // fieldId={document.variableFields[251]?.id}
                    />
                  </span>
                  <span>
                    <b>Low</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
