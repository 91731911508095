import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Row, Col } from "antd";

import pages from "./Pages";

export const ITCSCv84Pages = pages;

export const ITCSCv84Header = (props) => {
  const document = props?.state?.document;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldNames = [
    "document_classification_top_secret",
    "document_classification_secret",
    "document_classification_confidential",
    "document_classification_cui",
    "document_classification_unclassified",
  ];

  return (
    <Row justify="center" style={{ marginTop: 20 }}>
      <div style={{ width: "150px" }}>
        <SheetClassification
          fieldNames={fieldNames}
          elementProps={elementProps}
          document={document}
        />
      </div>
    </Row>
  );
};

export const ITCSCv84Footer = (props) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const { state, pageNumber } = props;

  const document = state?.document;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldNames = [
    "document_classification_top_secret",
    "document_classification_secret",
    "document_classification_confidential",
    "document_classification_cui",
    "document_classification_unclassified",
  ];

  // console.log({ document, state: props?.state });

  return (
    <Row justify="space-between">
      <Col span={8}>
        <p style={style}>DAF ITCSC Version 8.4 – 24 Mar 2023</p>
      </Col>
      <Col span={4.5}>
        <div style={{ width: "150px" }}>
          <SheetClassification
            fieldNames={fieldNames}
            elementProps={elementProps}
            document={document}
          />
        </div>
      </Col>
      <Col span={8}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {document?.numberOfPages}
        </p>
      </Col>
    </Row>
  );
};

export const ClassificationSelect = ({ elementProps, config, style = {} }) => {
  if (config.fieldIds.length < 1) console.log("no fieldIds in config", { elementProps });

  if (config.fieldNames.length < 1) console.log("no fieldNames in config", { elementProps });

  if (!config.label) console.log("no label in config", { elementProps });

  return (
    <EditableSelect
      {...elementProps}
      style={{ fontSize: 11, ...style }}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "UNCLASSIFIED", value: config.fieldIds[4] },
        { label: "TOP SECRET", value: config.fieldIds[0] },
        { label: "SECRET", value: config.fieldIds[1] },
        { label: "CONFIDENTIAL", value: config.fieldIds[2] },
        { label: "CUI", value: config.fieldIds[3] },
      ]}
    />
  );
};

export const SheetClassification = ({ fieldNames, elementProps }) => {
  const { document } = elementProps?.state;
  const fieldIds = document?.variableFields?.slice(0, 5).map((f) => f.id);
  return (
    <ClassificationSelect
      style={{ width: "100%" }}
      elementProps={elementProps}
      config={{
        label: "Select Classification",
        value: "document_classification_marking",
        fieldIds,
        fieldNames,
        // fieldIds: [
        //   document?.variableFields[0]?.id,
        //   document?.variableFields[1]?.id,
        //   document?.variableFields[2]?.id,
        //   document?.variableFields[3]?.id,
        //   document?.variableFields[4]?.id,
        // ],
      }}
    />
  );
};

export const InformationTypeSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "HIGH", value: config.fieldIds[0] },
        { label: "MODERATE", value: config.fieldIds[1] },
        { label: "LOW", value: config.fieldIds[2] },
      ]}
    />
  );
};

export const FirstCategorySubmissionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "Yes", value: config.fieldIds[0] },
        { label: "No", value: config.fieldIds[1] },
      ]}
    />
  );
};

export const EmassClassificationSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "NIPR", value: config.fieldIds[0] },
        { label: "SIPR", value: config.fieldIds[1] },
        { label: "N/A", value: config.fieldIds[2] },
      ]}
    />
  );
};

// prettier-ignore
export const RmfActionSelect = ({ elementProps, config, style = {} }) => {
  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        { label: "Assess & Authorize", value: config.fieldIds[0] },
        { label: "Assess Only - Assess and Incorporate", value: config.fieldIds[1] },
        {label: "Assess Only - Assess and Approve for Use and Inherit Process",
          value: config.fieldIds[2],
        },
      ]}
    />
  );
};
