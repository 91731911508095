import { useReducer } from "react";
import { useLocation } from "react-router-dom";
import { message, Button, Space } from "antd";
import { useQuery } from "@apollo/client";
import documents from "./index";
import DocumentSheet from "./Components/DocumentSheet";
import PageNotFound from "../Common/PageNotFound";
import Api from "api";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { editorStateReducer, initialEditorState } from "./reducer";
import "styles/documentsGen.css";
import { PrinterOutlined } from "@ant-design/icons";

const DocumentGenerator = () => {
  const location = useLocation();

  const { documentId, applicationId, id } = location.state;

  const [state, dispatch] = useReducer(editorStateReducer, initialEditorState);

  const { data, loading } = useQuery(Api.Document.GetAppDocument(), {
    variables: {
      applicationId: applicationId,
      documentId: id,
    },
    onCompleted: (res) => {
      console.log({ GetAppDocument: res });
      if (!res?.applicationDocument) return;
      dispatch({
        type: "INIT_ANSWERS",
        state: {
          fieldAnswers: res.applicationDocument,
        },
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const { data: data2, loading: loading2 } = useQuery(Api.Document.GetOne(), {
    variables: {
      id,
    },
    onCompleted: (res) => {
      if (!res?.document) return;
      console.log({ ViewerDoc: res.document });
      dispatch({
        type: "INIT_DOCUMENT",
        state: {
          document: res.document,
        },
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  console.log({ DocumentId: documentId });

  const doc = documents.find((d) => d.id === documentId);
  if (!doc) {
    message.error("Document not found");
    return <PageNotFound />;
  }

  if (loading || loading2 || !state.document) {
    return <LoadingSpinner />;
  }

  const header = doc.header;
  const formId = doc.id;
  const footer = doc.footer;

  const document = data2?.document;

  console.log({ document, AppDocQueryDoc: data?.applicationDocument });

  // console.log("%c VariableFields: ", "color:cyan", {
  //   Fields: data2?.document?.variableFields,
  // });

  console.log("%c Generator State: ", "color:purple", { state });

  return (
    <>
      <div className="documentGen">
        {doc.forms.map((form, i) => {
          const sheetProps = {
            editable: false,
            state,
            pageNumber: i + 1,
            formId,
            header,
            form,
            footer,
          };
          return <DocumentSheet key={i} {...sheetProps} />;
        })}
      </div>
      <div>
        <PrintButton />
      </div>
    </>
  );
};

export default DocumentGenerator;

const PrintButton = () => (
  <div
    style={{
      position: "fixed",
      bottom: 20,
      right: 20,
    }}
  >
    <Button
      type="primary"
      onClick={() => window.print()}
      style={{ display: "grid", placeItems: "center", padding: "5px 20px 10px" }}
    >
      <PrinterOutlined style={{ fontSize: 18 }} />
    </Button>
  </div>
);
