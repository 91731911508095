import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import P9D1 from "../Assets/P9D1.png";
import P9D2 from "../Assets/P9D2.png";

export const P9 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 80 }}>
          <div>
            <b style={{ fontSize: 16, paddingRight: 10 }}>FACTOR 3 - DATA FIELD SENSITIVITY</b>
          </div>
          <img src={P9D1} alt="P8D1" style={{ width: "100%" }} />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>
            Factor 3. Select Data Field Sensitivity impact value
          </b>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <EditableRadio {...elementProps} fieldName="9_data_field_sensitivity_low" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>LOW</span>
            </div>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="9_data_field_sensitivity_moderate"
              />
              <span style={{ marginLeft: 10, fontSize: 14 }}>MODERATE</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="9_data_field_sensitivity_high" />
              <span style={{ marginLeft: 10, fontSize: 14 }}>HIGH</span>
            </div>
          </div>
          <br />
          <b>Provide justification:</b>
          <div>
            <EditableField
              {...elementProps}
              fieldName="9_data_field_sensitivity_text"
              style={{ width: "100%", height: 100 }}
            />
          </div>
          <br />
        </div>

        <div style={{ paddingLeft: 80 }}>
          <div>
            <b style={{ fontSize: 16, paddingRight: 10 }}>
              FACTOR 4 - OBLIGATION TO PROTECT CONFIDENTIALITY
            </b>
          </div>
          <img src={P9D2} alt="P8D1" style={{ width: "100%" }} />
        </div>

        <br />
        <br />
        <br />
        <br />
        <div style={{ paddingLeft: 80 }}>
          <span style={{ fontSize: 8 }}>6 </span>Ibid
        </div>
        <div style={{ paddingLeft: 80 }}>
          <span style={{ fontSize: 8 }}>7 </span>Ibid
        </div>
      </div>
    </>
  );
};
