import EditableField from "../../../Components/EditableField";
import { FieldWrapper } from ".";

export const P2 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <div className="ctpDocument">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div className="fake-list-container">
          <p className="list-label">2.&nbsp; Responsible Organization</p>
          <FieldWrapper height={45}>
            <EditableField {...elementProps} fieldName="2_responsible_organization" />
          </FieldWrapper>
          <p className="list-label">3. &nbsp; References</p>
          <FieldWrapper height={60}>
            <EditableField {...elementProps} fieldName="2_references" />
          </FieldWrapper>
          <p className="list-label">4. &nbsp; Interrelationships</p>
          <FieldWrapper height={40}>
            <EditableField {...elementProps} fieldName="2_interrelationships" />
          </FieldWrapper>
          <FieldWrapper height={60} styles={{ marginLeft: 8, width: "calc(100% - 8px)" }}>
            <EditableField {...elementProps} fieldName="2_interrelationships_a" />
          </FieldWrapper>
          <FieldWrapper height={30}>
            <EditableField {...elementProps} fieldName="2_interrelationships_b" />
          </FieldWrapper>
          <div className="list-label-container">
            <p className="list-label">5. &nbsp; Verification Tract Matrix (VTM)</p>
          </div>
          <div className="list-label-container">
            <p className="list-label number">5.1</p>
            <p className="list-label">Verification Trace Matrix Description</p>
          </div>
          <p className="text-content">
            The VTM identifies the test category and method of testing for verifying compliance
            with the requirements specified. Each entry in the trace matrix contains the
            requirement number, reference to the system specification, the requirement text,
            the appropriate test method which satisfies the stated requirement, the test case
            which satisfies the requirement, test methods(s) that apply to the requirement and
            any applicable comments.
          </p>
          <div className="list-label-container">
            <p className="list-label number">5.2</p>
            <p className="list-label">VTM Compliance Methods</p>
          </div>

          <div className="list-row-container">
            <p style={{ minWidth: 40 }}>5.2.1</p>
            <p style={{ fontWeight: 500 }}>
              <strong style={{ height: 20 }}>Analysis (A) Method: </strong>
              Consists of a theoretical treatment performed using generally recognized and
              accepted mathematical techniques (which may include simulation or computer
              analysis using appropriate models) to determine conformance to requirements.
            </p>
          </div>

          <div className="list-row-container">
            <p style={{ minWidth: 40 }}>5.2.1</p>
            <p style={{ fontWeight: 500 }}>
              <strong style={{ height: 20 }}>Demonstration (D) Method: </strong>
              Consists of the exercise of hardware, software or operations (including
              simulators) in a specified environment such that pre-designated input produces
              known, predictable and repeatable output that can be readily observed and
              qualitatively evaluated to determine conformance to the requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
