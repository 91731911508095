import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P9 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const NSSMarking = {
    label: "Select Marking",
    value: "national_security_system_classification_marking",
    fieldIds: [
      fieldId("national_security_system_status_determination_marking_top_secret"),
      fieldId("national_security_system_status_determination_marking_secret"),
      fieldId("national_security_system_status_determination_marking_confidential"),
      fieldId("national_security_system_status_determination_marking_cui"),
      fieldId("national_security_system_status_determination_marking_unclassified"),
      // document.variableFields[173]?.id,
      // document.variableFields[174]?.id,
      // document.variableFields[175]?.id,
      // document.variableFields[176]?.id,
      // document.variableFields[177]?.id,
    ],
    fieldNames: [
      "national_security_system_status_determination_marking_top_secret",
      "national_security_system_status_determination_marking_secret",
      "national_security_system_status_determination_marking_confidential",
      "national_security_system_status_determination_marking_cui",
      "national_security_system_status_determination_marking_unclassified",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>
                11. National Security System (NSS) Status Determination
              </b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={NSSMarking} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  11A. Does the function, operation, or use of the system involve intelligence
                  activities?
                </b>
                <div>
                  Ref: (n) DoD Memorandum (DoD Memo) and National Manager Memorandum
                  (NMM)-2022-05
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11a_yes"
                      // fieldId={document.variableFields[178]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11a_no"
                      // fieldId={document.variableFields[179]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  11B. Does the function, operation, or use of the system involve cryptologic
                  activities related to national security?
                </b>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11b_yes"
                      // fieldId={document.variableFields[180]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11b_no"
                      // fieldId={document.variableFields[181]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  11C. Does the function, operation, or use of the system involve military
                  command and control of military forces?
                </b>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11c_yes"
                      // fieldId={document.variableFields[182]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11c_no"
                      // fieldId={document.variableFields[183]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  11D. Does the function, operation, or use of the system involve equipment
                  that is an integral part of the weapon or weapons system?
                </b>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11d_yes"
                      // fieldId={document.variableFields[184]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11d_no"
                      // fieldId={document.variableFields[185]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  11E. If the use of the system is not a routine administrative or business
                  application, is the system critical to the direct fulfillment of military or
                  intelligence missions?
                </b>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11e_yes"
                      // fieldId={document.variableFields[186]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11e_no"
                      // fieldId={document.variableFields[187]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                11F. Does the system store, process, or communicate classified information?
                </b>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11f_yes"
                      // fieldId={document.variableFields[186]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11f_no"
                      // fieldId={document.variableFields[187]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>
          
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>
                  11G. Does the system process any information the unauthorized access, use,
                  disclosure, disruption, modification, or destruction of which would have a
                  debilitating impact on the mission of the Department of Defense or an element
                  of the Intelligence Community?
                </b>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11g_yes"
                      // fieldId={document.variableFields[188]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11g_no"
                      // fieldId={document.variableFields[189]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <b>
                    11H. Is the system designated as NSS per organizationally defined guidance
                    but does not meet any of the above criteria? If yes, then an appropriate
                    explanation must be provided.
                  </b>
                </div>
                <div>Ref: (n) DoD Memo and NMM-2002-05</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11h_yes"
                      // fieldId={document.variableFields[190]?.id}
                    />
                  </span>
                  <b>Yes</b> Provide Explaination
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11h_no"
                      // fieldId={document.variableFields[191]?.id}
                    />
                  </span>
                  <div style={{ width: "100%", marginRight: 5 }}>
                    <b>No</b>
                    <div style={{ width: "100%", height: "175px" }}>
                      <EditableField
                        {...elementProps}
                        fieldName="11h_no_additional"
                        // fieldId={document.variableFields[192]?.id}
                        rows={3}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.6,
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                <b>11I. Is this an NSS?</b>
                <i>
                  NOTE 13: If the answer to any of the eight questions above is “Yes,” then the
                  system is an NSS
                </i>
                <div>Ref: (n) DoD Memo and NMM-2022-05</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.4,
                padding: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "left",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11i_yes"
                      // fieldId={document.variableFields[193]?.id}
                    />
                  </span>
                  <span>
                    <b>Yes</b>
                  </span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableRadio
                      {...elementProps}
                      fieldName="11i_no"
                      // fieldId={document.variableFields[194]?.id}
                    />
                  </span>
                  <b>No</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
