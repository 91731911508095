import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Row, Col } from "antd";

export const P3 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const TDPMarking = {
    label: "Select Marking",
    value: "tdp_classification_marking",
    fieldIds: [
      fieldId("tdp_tdp_marking_top_secret"),
      fieldId("tdp_marking_secret"),
      fieldId("tdp_marking_confidential"),
      fieldId("tdp_marking_cui"),
      fieldId("tdp_marking_unclassified"),
      // document.variableFields[75].id,
      // document.variableFields[76].id,
      // document.variableFields[77].id,
      // document.variableFields[78].id,
      // document.variableFields[79].id,
    ],
    fieldNames: [
      "tdp_tdp_marking_top_secret",
      "tdp_marking_secret",
      "tdp_marking_confidential",
      "tdp_marking_cui",
      "tdp_marking_unclassified",
    ],
  };

  const SDEMarking = {
    label: "Select Marking",
    value: "sde_classification_marking",
    fieldIds: [
      fieldId("sde_marking_top_secret"),
      fieldId("sde_marking_secret"),
      fieldId("sde_marking_confidential"),
      fieldId("sde_marking_cui"),
      fieldId("sde_marking_unclassified"),
      // document.variableFields[81].id,
      // document.variableFields[82].id,
      // document.variableFields[83].id,
      // document.variableFields[84].id,
      // document.variableFields[85].id,
    ],
    fieldNames: [
      "sde_marking_top_secret",
      "sde_marking_secret",
      "sde_marking_confidential",
      "sde_marking_cui",
      "sde_marking_unclassified",
    ],
  };

  const SOSMarking = {
    label: "Select Marking",
    value: "sos_classification_marking",
    fieldIds: [
      fieldId("sos_marking_top_secret"),
      fieldId("sos_marking_secret"),
      fieldId("sos_marking_confidential"),
      fieldId("sos_marking_cui"),
      fieldId("sos_marking_unclassified"),
      // document.variableFields[87].id,
      // document.variableFields[88].id,
      // document.variableFields[89].id,
      // document.variableFields[90].id,
      // document.variableFields[91].id,
    ],
    fieldNames: [
      "sos_marking_top_secret",
      "sos_marking_secret",
      "sos_marking_confidential",
      "sos_marking_cui",
      "sos_marking_unclassified",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                border: "1px solid black",
                backgroundColor: "lightgrey",
              }}
            >
              <div>
                <b style={{ fontSize: 14 }}>3. Technical Description/Purpose</b>
              </div>
              <div style={{ textAlign: "left", width: "150px" }}>
                <ClassificationSelect elementProps={elementProps} config={TDPMarking} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              <i style={{ paddingLeft: 5, textAlign: "left" }}>
                NOTE 4: Provide a description of the security environment. All acronyms must be
                spelled out once in the description. Failure to spell out acronyms once may
                result in the form being returned. This description should tell the story of
                who, what, where, when, why, and how cybersecurity and information assurance
                hardware and software is, or will be, protecting the system
              </i>
            </div>
            <div
              style={{
                display: "flex",
                border: "1px solid black",
                borderTop: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "225px",
                  fontSize: 14,
                }}
              >
                <EditableField
                  borderless
                  {...elementProps}
                  fieldName="technical_description"
                  // fieldId={document.variableFields[80].id}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              borderTop: "none",
              backgroundColor: "lightgrey",
            }}
          >
            <div>
              <b style={{ fontSize: 14 }}>4. Security Description/Environment</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={SDEMarking} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <i style={{ paddingLeft: 5, textAlign: "left" }}>
              NOTE 5: Describe your Defense-in-Depth: Provide a narrative, DoDAF view or other
              artifact that captures processes in place to protect the information and or
              system
            </i>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderTop: "none",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "225px",
                fontSize: 14,
              }}
            >
              <EditableField
                borderless
                {...elementProps}
                fieldName="security_description"
                // fieldId={document.variableFields[86].id}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <div>
              <b style={{ fontSize: 14 }}>5. System Operational Status</b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={SOSMarking} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
              padding: 5,
            }}
          >
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="sos_operational"
                // fieldId={document.variableFields[92].id}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <b>Operational</b> - System is in production (Initial Operating Capability/Full
              Operating Capability)
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
              padding: 5,
            }}
          >
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="sos_under_development"
                // fieldId={document.variableFields[93].id}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <b>Under Development</b> – System is in design phase
            </div>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              padding: 5,
            }}
          >
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="sos_major_modification"
                // fieldId={document.variableFields[94].id}
              />
            </div>
            <div style={{ marginLeft: 5 }}>
              <b>Major Modification</b> – System is undergoing significant change
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
