import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Space } from "antd";

export const P5 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props?.pageNumber
  };


  const SSTMarking = {
    label: "Select Marking",
    value: "system_or_subsystem_type_marking",
  }

  const SOSMarking = {
    label: "Select Marking",
    value: "system_operational_status_marking",
  }

  const SDPMarking = {
    label: "Select Marking",
    value: "system_description_purpose_marking",
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: 'column', height: '100%' }}>
        {/* 5. System or Subsystem */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="33%" />
              <col span={1} width="33%" />
              <col span={1} width="33%" />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={SSTMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      5. System or Subsystem Type <i>Ref: (d)</i>
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="label" colSpan={3}>System Type</td>
              </tr>

              <tr>
                <td colSpan={3}>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '10px' }}>
                    <EditableRadio {...elementProps} fieldName="5_system_type_enclave" label="Enclave" style={{ width: 'fit-content' }} />
                    <EditableRadio {...elementProps} fieldName="5_system_type_major_application" label="Major Application" style={{ width: 'fit-content' }} />
                    <EditableRadio {...elementProps} fieldName="5_system_type_pit" label="Platform Information Technology (PIT) System" style={{ width: 'fit-content' }} />
                  </div>
                  <i>Note 5: Systems are Assess and Authorize (A&A)</i>
                </td>
              </tr>

              <tr> <td colSpan={3} className="label">Subsystem Type - PIT/Services/Products</td> </tr>

              <tr>
                <td className="label" style={{ textAlign: 'center', borderBottom: 'none' }}>PIT</td>
                <td className="label" style={{ textAlign: 'center', borderBottom: 'none' }}>Services</td>
                <td className="label" style={{ textAlign: 'center', borderBottom: 'none' }}>Products</td>
              </tr>

              <tr>
                <td style={{ verticalAlign: 'top', borderBottom: 'none', borderTop: 'none' }} >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ textAlign: 'left' }}>
                      <Space direction="vertical">
                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_pit_products" label="PIT Product(s)" />
                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_pit_subsystems" label="PIT Subsystem(s)" />
                      </Space>
                    </div>
                  </div>
                </td>

                <td style={{ verticalAlign: 'top', borderBottom: 'none', borderTop: 'none' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ textAlign: 'left' }}>
                      <Space direction="vertical">
                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_services_external" label="External" />
                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_services_internal" label="Internal" />
                      </Space>
                    </div>
                  </div>
                </td>

                <td style={{ verticalAlign: 'top', borderBottom: 'none', borderTop: 'none' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ textAlign: 'left' }}>
                      <Space direction="vertical">

                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_products_application" label="Application" />
                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_products_hardware" label="Hardware" />
                        <EditableCheckbox {...elementProps} fieldName="5_subsystem_type_products_software" label="Software" />
                      </Space>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: 'none', borderBottom: 'none' }} className="text"><i>Note 6: PIT are Assess Only</i></td>
                <td style={{ borderTop: 'none', borderBottom: 'none' }} className="text"><i>Note 7: System Services are Assess Only</i></td>
                <td style={{ borderTop: 'none', borderBottom: 'none' }} className="text"><i>Note 8: System Products are Assess Only</i></td>
              </tr>

            </tbody>
          </table>
        </div>

        {/* 6. System Operational Status */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={SOSMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      6. System Operational Status
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody >
              <tr height={30}>
                <td className="text">
                  <EditableRadio {...elementProps} fieldName="6_system_operational_status_operational"
                    label={<><b>Operational</b> – System is in production (Initial Operating Capability (IOC) / Full Operating Capability (FOC))</>}
                  />
                </td>
              </tr>
              <tr height={30}>
                <td className="text">
                  <EditableRadio {...elementProps} fieldName="6_system_operational_status_under_development"
                    label={<><b>Under Development</b> – System is in design phase</>}
                  />
                </td>
              </tr>
              <tr height={30}>
                <td className="text" style={{ borderBottom: 'none' }}>
                  <EditableRadio {...elementProps} fieldName="6_system_operational_status_major_modification"
                    label={<><b>Major Modification</b> – System is undergoing significant change</>}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* 7. System Description / Purpose */}
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width="100%" />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={SDPMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                    7. System Description / Purpose
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text">
                  <i>
                    Note 9: Provide a description of the system environment. All acronyms must be spelled out first time in the<br />
                    description. Failure to spell out acronyms may result in the form being returned. This description should tell the story<br />
                    of who, what, where, when, why, and how the information and system will be protected against digital attacks.
                  </i>
                </td>
              </tr>
              <tr>
                <td>
                  <EditableField {...elementProps} fieldName="7_system_description_purpose"
                    style={{ minHeight: '400px' }}
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </div>


      </div>
    </>
  );
};
