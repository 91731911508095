import { useState, useRef, useEffect } from "react";
import { baseTableProps, getColumnSearchProps } from "../Shared/props";
import { Table, TableProps } from "antd";

/**
 * SmartTable
 * A reusable table component that allows for sorting, select and search filtering
 *
 * ? Sorting
 * 1. include sorter fn to column
 *
 * ? Selection Filtering
 * 1. include filters list to column
 * 2. include onFilter compare fn to column
 *
 * ? Search Filtering
 * 1. include dataIndex of column in searchColumnKeys prop
 */

interface Props extends TableProps<any> {
  loading?: boolean;
  columns: any;
  data: Array<any>;
  searchColumnKeys?: Array<string>;
  onChange?: (change: any) => void;
}

const SmartTable: React.FC<Props> = (props) => {
  const {
    loading = false,
    columns,
    data,
    searchColumnKeys = [],
    onChange = () => {},
    ...rest
  } = props;

  const searchInput: any = useRef(null);
  const [filteredData, setFilteredData] = useState<any>({});
  const [tableData, setTableData] = useState<Array<any>>([]);

  useEffect(() => {
    if (!data) return;
    setTableData(data);
  }, [data, loading]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredData(filters);

    // ? pass change data to component callback for use in parent renderer
    onChange({ pagination, filters, sorter, extra });
  };

  const tableColumns = columns.map((c) => {
    let column = {
      ...c,
      filteredValue: filteredData[c.dataIndex] || null,
    };

    if (searchColumnKeys.some((key) => key === c.dataIndex)) {
      column = {
        ...getColumnSearchProps(c.dataIndex, searchInput),
        ...column,
      };
    }

    return column;
  });

  return (
    <Table
      {...baseTableProps(loading)}
      columns={tableColumns}
      dataSource={tableData}
      loading={rest.locale ? undefined : loading}
      onChange={handleTableChange}
      {...rest}
    />
  );
};

SmartTable.defaultProps = {
  onChange() {},
};

export default SmartTable;
