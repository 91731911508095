import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { Col, Row } from "antd";

export const P4 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        className="doc_pia"
        style={{
          width: "100%",
          fontSize: 11,
        }}
      >
        <Row justify="center">
          <div>
            <b>
              <u>SECTION 2: PII RISK REVIEW</u>
            </b>
          </div>
        </Row>
        <div style={{ display: "flex" }}>
          <Row style={{ padding: "0 10px" }}>
            <b>a.</b>
          </Row>
          <Row>
            <b>
              What PII will be collected (a data element alone or in combination that can
              uniquely identify an individual)? (Check all hat apply)
            </b>
          </Row>
        </div>
        <Row style={{ paddingLeft: 10, paddingTop: 5 }}>
          <Col span={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_biometrics" />
                <span style={{ marginLeft: 10 }}>Biometrics</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_citizenship" />
                <span style={{ marginLeft: 10 }}>Citizenship</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_drivers_license" />
                <span style={{ marginLeft: 10 }}>Drivers License</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_employment_info" />
                <span style={{ marginLeft: 10 }}>Employment information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_home_cel" />
                <span style={{ marginLeft: 10 }}>Home Cell Phone</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_mailing_address" />
                <span style={{ marginLeft: 10 }}>Mailing home Address</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_military_records" />
                <span style={{ marginLeft: 10 }}>Military Records</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_official_duty_address" />
                <span style={{ marginLeft: 10 }}>Official Duty Address</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_passport_info" />
                <span style={{ marginLeft: 10 }}>Passport information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_place_of_birth" />
                <span style={{ marginLeft: 10 }}>Place of Birth</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_race_ethnicity" />
                <span style={{ marginLeft: 10 }}>Race Ethnicity</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_records" />
                <span style={{ marginLeft: 10 }}>Records</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_work_email_address" />
                <span style={{ marginLeft: 10 }}>Work E-mail Address</span>
              </Col>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_birth_date" />
                <span style={{ marginLeft: 10 }}>Birth Date</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_disability_info" />
                <span style={{ marginLeft: 10 }}>Disability Information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_education_info" />
                <span style={{ marginLeft: 10 }}>Education information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_financial_info" />
                <span style={{ marginLeft: 10 }}>Financial Information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_law_enforcement_info" />
                <span style={{ marginLeft: 10 }}>Law Enforcement Information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_marital_status" />
                <span style={{ marginLeft: 10 }}>Marital Status</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_mothers_maiden" />
                <span style={{ marginLeft: 10 }}>Mothers Middle/Maiden Name</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="4_a_official_duty_telephone_phone"
                />
                <span style={{ marginLeft: 10 }}>Official Duty Telephone Phone</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_personal_email_address" />
                <span style={{ marginLeft: 10 }}>Personal E-mail Address</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_position_title" />
                <span style={{ marginLeft: 10 }}>Position/Title</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_rank_grade" />
                <span style={{ marginLeft: 10 }}>Rank/Grade</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_security_information" />
                <span style={{ marginLeft: 10 }}>Security information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox
                  {...elementProps}
                  fieldName="4_a_other_info"
                  style={{ height: "10px" }}
                />
                <span style={{ marginLeft: 10, paddingRight: 10 }}>
                  If Other, enter the information in the box below
                </span>
              </Col>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_child_info" />
                <span style={{ marginLeft: 10 }}>Child Information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_dod_id_number" />
                <span style={{ marginLeft: 10 }}>DoD ID Number</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_emergency_contact" />
                <span style={{ marginLeft: 10 }}>Emergency Contact</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_gender_identification" />
                <span style={{ marginLeft: 10 }}>Gender/Gender identification</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_legal_status" />
                <span style={{ marginLeft: 10 }}>Legal Status</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_medical_info" />
                <span style={{ marginLeft: 10 }}>Medical Information</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_names" />
                <span style={{ marginLeft: 10 }}>Name(s)</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_other_id_num" />
                <span style={{ marginLeft: 10 }}>Other ID Number</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_photo" />
                <span style={{ marginLeft: 10 }}>Photo</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_protected_health_info" />
                <span style={{ marginLeft: 10 }}>Protected Health Information (PH)</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_religious_preference" />
                <span style={{ marginLeft: 10 }}>Religious Preference</span>
              </Col>
              <Col style={{ display: "flex" }}>
                <EditableCheckbox {...elementProps} fieldName="4_a_ssn" />
                <span style={{ marginLeft: 10 }}>
                  Social Security Number (SSN) (Full or in any form)
                </span>
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <EditableField {...elementProps} fieldName="4_a_other_text" style={{ height: 40}} />
        </Row>

        <div style={{ paddingLeft: 10 }}>
          <Row style={{ paddingTop: 10 }}>
            If the SSN is collected, complete the following questions.
          </Row>
          <Row style={{ paddingTop: 10 }}>
            (DoD Instruction 1000.30 states that all DoD personnel shall reduce or eliminate
            the use of SSNs wherever possible. SSNs shall not be sed in spreadsheets, hard copy
            fists, electronic reports, or collected in surveys unless they meet one or more of
            the acceptable use criteria.)
          </Row>
          <Row style={{ paddingLeft: 20, paddingTop: 10 }}>
            (1) Is there a current (dated within two (2) years) DPCLTD approved SSN
            Justification on Memo in place?
          </Row>

          <Row style={{ padding: "5px 0" }}>
            <div style={{ marginRight: 10 }}>
              <EditableRadio {...elementProps} fieldName="4_a_dod_dpcltd_approved_ssn_yes" />
              <span style={{ marginLeft: 10 }}>Yes</span>
            </div>
            <div>
              <EditableRadio {...elementProps} fieldName="4_a_dod_dpcltd_approved_ssn_no" />
              <span style={{ marginLeft: 10 }}>No</span>
            </div>
          </Row>
          <Row>
            If "Yes," provide the signatory and date approval. If “No,” explain why there is no
            SSN Justification Memo.
            <EditableField
              {...elementProps}
              fieldName="4_a_dod_dpcltd_approved_ssn_yes_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row>
            (2) Describe the approved acceptable use in accordance with DoD Instruction 1000.30
            “Reduction of Social Security Number (SSN) Use within DoD"
            <EditableField
              {...elementProps}
              fieldName="4_a_dod_dpcltd_describe_approved_acceptable_use_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row>
            (3) Describe the mitigation efforts to reduce the use including visibility and
            printing of SSN in accordance with DoD Instruction 1000.30, “Reduction of Social
            Security Number (SSN) Use within DoD"
            <EditableField
              {...elementProps}
              fieldName="4_a_dod_dpcltd_describe_mitigation_efforts_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row style={{ padding: "5px 10px" }}>
            (4) Has a plan to eliminate the use of the SSN or mitigate its use and or
            visibility been identified in the approved SSN Justification request?
          </Row>
          <Row style={{ paddingLeft: 30 }}>
            If "Yes", provide the unique identifier and when it can be eliminated
          </Row>
          <Row style={{ paddingLeft: 30 }}>
            If "No", explain
          </Row>
          <Row style={{ padding: "5px 10px" }}>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_a_4_plan_eliminate_ssn_or_use_yes"
              />
              <span style={{ marginLeft: 10 }}>Yes</span>
            </div>
            <div>
              <EditableRadio
                {...elementProps}
                fieldName="4_a_4_plan_eliminate_ssn_or_use_no"
              />
              <span style={{ marginLeft: 10 }}>No</span>
            </div>
          </Row>

          <Row>
            <EditableField
              {...elementProps}
              fieldName="4_a_4_plan_eliminate_ssn_or_use_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row style={{ padding: "5px 0" }}>
            <b style={{ marginRight: 20 }}>What is the PII confidentiality impact level?</b>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_b_pii_confidentiality_impact_low"
              />
              <span style={{ marginLeft: 10 }}>Low</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_b_pii_confidentiality_impact_moderate"
              />
              <span style={{ marginLeft: 10 }}>Moderate</span>
            </div>
            <div style={{ marginRight: 10 }}>
              <EditableRadio
                {...elementProps}
                fieldName="4_b_pii_confidentiality_impact_high"
              />
              <span style={{ marginLeft: 10 }}>High</span>
            </div>
          </Row>

          <Row>
            <EditableField
              {...elementProps}
              fieldName="4_b_pii_confidentiality_impact_text"
              style={{ height: 40 }}
            />
          </Row>

          <Row style={{ fontSize: 8, paddingTop: 5 }}>
            <div>
              <span style={{ fontSize: 0.7 }}>1</span>
              The definition of PHI involves evaluating conditions in the HIPAA. Consult with
              General Counsel to make this determination.
            </div>
            <div>
              <span style={{ fontSize: 0.7 }}>2</span>
              Guidance on determining the PI confidentiality impact lev, see Section 2.5
              “Categorization of Pl Using NIST SP 800-122." see the identified PIl confidently
              impact level io apply the appropriate Privacy Overlay ow, moderate or high. Ths
              activity may be conducted 2 pr fhe categorization exercise ha course under the
              Rsk Management Framework (RMF). Note that categorization under the RMF typically
              conduced using information types described NIST Special Publication (SP) 30080,
              which are not as granular as the PII data elements listed in the PIA table.
              Determining the PII confidentiality impact level is most effective when done in
              collaboration with the Information Owner, Information System Owner, Information
              System Security Manager, and representatives from the security and privacy
              organizations, such as the Information System Security Officer (ISSO) and Senior
              Component Official for Privacy (SCOP) or designees.
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};
