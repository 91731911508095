import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P6 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const PSMarking = {
    label: "Select Marking",
    value: "proposed_system_classification_marking",
    fieldIds: [
      fieldId("proposed_system_marking_top_secret"),
      fieldId("proposed_system_marking_secret"),
      fieldId("proposed_system_marking_confidential"),
      fieldId("proposed_system_marking_cui"),
      fieldId("proposed_system_marking_unclassified"),
      // document.variableFields[134]?.id,
      // document.variableFields[135]?.id,
      // document.variableFields[136]?.id,
      // document.variableFields[137]?.id,
      // document.variableFields[138]?.id,
    ],
    fieldNames: [
      "proposed_system_marking_top_secret",
      "proposed_system_marking_secret",
      "proposed_system_marking_confidential",
      "proposed_system_marking_cui",
      "proposed_system_marking_unclassified",
    ],
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              border: "1px solid black",
              backgroundColor: "lightgrey",
              borderTop: "1px solid black",
              borderBottom: "none",
            }}
          >
            <div style={{ flex: 1 }}>
              <b style={{ fontSize: 14 }}>
                8. Proposed System (Select One System Category and Describe Boundary)
              </b>
            </div>
            <div style={{ textAlign: "left", width: "150px" }}>
              <ClassificationSelect elementProps={elementProps} config={PSMarking} />
            </div>
          </div>
          <div
            style={{
              padding: "0 5px",
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ fontSize: 14 }}>
              8A. System Type - Information Systems(IS)/PIT Systems Ref: (o)
            </b>
          </div>
          <div
            style={{
              padding: "40px 0 60px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid black",
              borderBottom: "none",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <div>
                <span style={{ margin: "0 5px" }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="8a_enclave"
                    // fieldId={document.variableFields[139]?.id}
                  />
                </span>
                <span>Enclave</span>
              </div>
              <div>
                <span style={{ margin: "0 5px" }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="8a_major_application"
                    // fieldId={document.variableFields[140]?.id}
                  />
                </span>
                <span>Major Application</span>
              </div>
              <div>
                <span style={{ margin: "0 5px" }}>
                  <EditableRadio
                    {...elementProps}
                    fieldName="8a_pit_system"
                    // fieldId={document.variableFields[141]?.id}
                  />
                </span>
                <span>Platform Information Technology (PIT) System</span>
              </div>
              <div style={{ position: "absolute", bottom: 10, left: 10 }}>
                <i>NOTE 7: Information Systems/PIT Systems are Assess and Authorize (A&A)</i>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "0 5px",
              display: "flex",
              border: "1px solid black",
              borderBottom: "none",
            }}
          >
            <b style={{ fontSize: 14 }}>
              8B. System Type - PIT/Products and Services Ref: (p)
            </b>
          </div>
          <div
            style={{
              display: "flex",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                padding: "40px 0",
                flex: 0.333,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
                position: "relative",
              }}
            >
              <b style={{ position: "absolute", top: 10, left: 10 }}>PIT</b>
              <div
                style={{
                  padding: "20px 0",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b1_pit_products"
                      // fieldId={document.variableFields[142]?.id}
                    />
                  </span>
                  <span>PIT Product(s)</span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b1_pit_subsystems"
                      // fieldId={document.variableFields[143]?.id}
                    />
                  </span>
                  <span>PIT Subsystem(s)</span>
                </div>
              </div>
              <i style={{ position: "absolute", bottom: 10, left: 10 }}>
                NOTE 8: PIT are Assess Only
              </i>
            </div>
            <div
              style={{
                padding: "40px 0",
                flex: 0.333,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
                position: "relative",
              }}
            >
              <b style={{ position: "absolute", top: 10, left: 10 }}>System Services</b>
              <div
                style={{
                  padding: "20px 0",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b2_system_services_external"
                      // fieldId={document.variableFields[144]?.id}
                    />
                  </span>
                  <span>External</span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b2_system_services_internal"
                      // fieldId={document.variableFields[145]?.id}
                    />
                  </span>
                  <span>Internal</span>
                </div>
              </div>
              <i style={{ position: "absolute", bottom: 10, left: 10 }}>
                NOTE 9: System Services are Assess Only
              </i>
            </div>
            <div
              style={{
                padding: "40px 0",
                flex: 0.333,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <b style={{ position: "absolute", top: 10, left: 10 }}>System Products</b>
              <div
                style={{
                  padding: "20px 0",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b3_system_products_application"
                      // fieldId={document.variableFields[146]?.id}
                    />
                  </span>
                  <span>Application</span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b3_system_products_hardwar" // typo in the field name
                      // fieldId={document.variableFields[147]?.id}
                    />
                  </span>
                  <span>Hardware</span>
                </div>
                <div>
                  <span style={{ margin: "0 5px" }}>
                    <EditableCheckbox
                      {...elementProps}
                      fieldName="8b3_system_products_software"
                      // fieldId={document.variableFields[148]?.id}
                    />
                  </span>
                  <span>Software</span>
                </div>
              </div>
              <i style={{ position: "absolute", bottom: 10, left: 10 }}>
                NOTE 10: System Products are Assess Only
              </i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
