import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import DOAFSeal from "../Assets/DOAFSeal.png";
import P2D1 from "../Assets/P2D1.png";

export const P2 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        style={{
          fontSize: 48,
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
          margin: 0,
          color: "navy",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img style={{ width: "85px", margin: "10px" }} src={DOAFSeal} alt="" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ height: "35px", width: "175px", paddingTop: 5 }}>
            <EditableField {...elementProps} fieldName="aodb_program_name" />
          </span>
          <span style={{ marginLeft: 10 }}>
            <i> and AO Team</i>
          </span>
        </div>
      </div>
      <div
        style={{
          borderTop: "5px solid navy",
          borderBottom: "5px solid navy",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "0 40px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <b style={{ fontSize: 14, margin: "10px 0 0" }}>CRA Briefing:</b>
              <div>
                <p style={{ margin: "10px 0 2px" }}>PEO:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_peo" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>Deputy PEO:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_deputy_peo" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>CISO:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_ciso" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>ISO:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_iso" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>PM:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_pm" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>CRA Name:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_cra_name" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>ISSO:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_isso" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>ISSE:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_isse" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>Pen Test Lead:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_pen_test_lead" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>Program Support:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="cra_briefing_program_support" />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <b style={{ fontSize: 14, margin: "10px 0 0" }}>AO Team:</b>
              <div>
                <p style={{ margin: "10px 0 2px" }}>AO:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="ao_team_ao" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>AODR:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="ao_team_aodr" />
                </div>
              </div>
              <div>
                <p style={{ margin: "10px 0 2px" }}>CRA:</p>
                <div style={{ height: "25px", width: "300px" }}>
                  <EditableField {...elementProps} fieldName="ao_team_cra" />
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <img
                  style={{
                    width: "200px",
                  }}
                  src={P2D1}
                  alt="cuber_team_support"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                backgroundColor: "steelblue",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Team of Teams Concept: Teams collaborate, but assessors must be independent.
            </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};
