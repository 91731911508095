import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

import DOAFSeal from "../Assets/DOAFSeal.png";

export const P1 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <p
        style={{
          fontSize: 32,
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
          margin: 0,
        }}
      >
        U.S. Air Force
      </p>
      <div
        style={{
          borderTop: "5px solid navy",
          borderBottom: "5px solid navy",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 40px",
          }}
        >
          <p style={{ fontSize: 24, paddingTop: 10 }}>
            <i style={{ padding: "0 5px" }}>I n t e g r i t y</i> -{" "}
            <i style={{ padding: "0 5px" }}>S e r v i c e</i> -{" "}
            <i style={{ padding: "0 5px" }}>E x c e l l e n c e</i>
          </p>

          <div
            style={{
              display: "flex",
              minHeight: "100%",
              alignItems: "stretch",
            }}
          >
            <div
              style={{
                flex: 0.6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <div style={{ maxWidth: "150px" }}>
                <img src={DOAFSeal} alt="doaf_seal" />
              </div>
            </div>
            <div
              style={{
                flex: 1.4,
                textAlign: "right",
                display: "flex",
                alignItems: "end",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: 48,
                  fontWeight: "bold",
                  width: "100%",
                  padding: "0 20px",
                  color: "navy",
                }}
              >
                AO Determination Briefing
              </p>
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <div>
                  <p style={{ margin: "10px 0 2px" }}>Determination Type (IATT, ATO, etc):</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="aodb_determination_type" />
                  </div>
                </div>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>Program Name:</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="aodb_program_name" />
                  </div>
                </div>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>Program Type:</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="aodb_program_type" />
                  </div>
                </div>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>ITIPS ID/PID/eMASS ID:</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="aodb_itips_id_pid_emassid" />
                  </div>
                </div>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>Mission Area:</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="aodb_weapons_logistics_etc" />
                  </div>
                </div>
                <b style={{ fontSize: 14, margin: "10px 0 0" }}>CRA Briefing:</b>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>CRA Name:</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField {...elementProps} fieldName="aodb_cra_brief_cra_name" />
                  </div>
                </div>
                <div>
                  <p style={{ margin: "10px 0 2px" }}>Briefing Date:</p>
                  <div style={{ height: "25px", width: "300px" }}>
                    <EditableField
                      {...elementProps}
                      fieldName="aodb_cra_brief_brief_date"
                      fieldType="FULFILL_DATE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div>
              Note* Add Classification and Portion Markings as appropriate (remove before
              submission).
            </div>
            <div>
              DISTRIBUTION STATEMENT A: Distribution approved for public release; distribution
              is unlimited.
            </div>
            <div>DESTRUCTION NOTICE: Must be completed when filled in.</div>
            <div>Case Number: AFLCMC-2021-0302</div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};
