import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P8 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props?.pageNumber
  };

  const SDEMarking = {
    label: "Select Marking",
    value: "security_description_environment_marking",
  };

  return (
    <>
      <div className="page-table">
        <table border={1}>

          <thead>
            <tr>
              <th className="title" colSpan={3}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                  <div style={{ paddingLeft: '10px' }}>
                    <ClassificationSelect elementProps={elementProps} config={SDEMarking} style={{ border: 'none' }} />
                  </div>
                  <div>
                    11. Security Description / Environment
                  </div>
                </div>

              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="text">
                <i>
                  Note 13: Describe your Defense-in-Depth: Provide a narrative DOD Architecture Framework (DODAF) view or other
                  artifact that captures processes in place to protect the information and system.
                </i>
              </td>
            </tr>


          </tbody>

        </table>
      </div>
      <EditableField {...elementProps} fieldName="11_security_description_environment"
        style={{ height: '90%' }}
      />

    </>
  );
};
