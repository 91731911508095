import { useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, message, Button } from "antd";
import { useQuery } from "@apollo/client";
import documents from "./index";
import DocumentSheet from "./Components/DocumentSheet";
import PageNotFound from "../Common/PageNotFound";
import Api from "api";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { editorStateReducer, initialEditorState } from "./reducer";
import "styles/documents.css";

const DocumentViewer = () => {
  const location = useLocation();

  const { documentId, applicationId, id } = location.state;

  const [state, dispatch] = useReducer(editorStateReducer, initialEditorState);

  const { data, loading } = useQuery(Api.Document.GetAppDocument(), {
    variables: {
      applicationId: applicationId,
      documentId: id,
    },
    onCompleted: (res) => {
      console.log({ GetAppDocument: res });
      if (!res?.applicationDocument) return;
      if (res.applicationDocument.length === 0) return;
      dispatch({
        type: "INIT_ANSWERS",
        state: {
          fieldAnswers: res.applicationDocument,
        },
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const { data: data2, loading: loading2 } = useQuery(Api.Document.GetOne(), {
    variables: {
      id,
    },
    onCompleted: (res) => {
      if (!res?.document) return;
      console.log({ ViewerDoc: res.document });
      dispatch({
        type: "INIT_DOCUMENT",
        state: {
          document: res.document,
        },
      });
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const doc = documents.find((d) => d.id === documentId);
  if (!doc) {
    message.error("Document not found");
    return <PageNotFound />;
  }

  if (loading || loading2 || !state.document) {
    return <LoadingSpinner />;
  }

  const header = doc.header;
  const formId = doc.id;
  const footer = doc.footer;

  const document = data2?.document;

  console.log("%c Viewer State: ", "color:purple", {
    state,
    document,
    AppDocQueryDoc: data?.applicationDocument,
  });

  return (
    <Row>
      <Col span={24}>
        <div className="documentView" id="deliverable">
          <div className="csardocument letter">
            {doc.forms.map((form, i) => {
              const sheetProps = {
                editable: false,
                state,
                pageNumber: i + 1,
                formId,
                header,
                form,
                footer,
              };
              return (
                <>
                  <DocumentSheet key={i} {...sheetProps} />
                  <br />
                </>
              );
            })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DocumentViewer;
