import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useQuery } from "@apollo/client";
import Api from "api";
import LoadingSpinner from "components/Common/LoadingSpinner";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";
import {
  Button,
  Row,
  Col,
  message,
  Space,
  Card,
  Segmented,
  Input,
  Divider,
  Avatar,
  Progress,
} from "antd";
import {
  CheckCircleTwoTone,
  DownloadOutlined,
  EyeOutlined,
  FileWordTwoTone,
  FormOutlined,
  MonitorOutlined,
} from "@ant-design/icons";
import { DeliverableDocumentNode, GetDeliverableDocumentsQuery } from "types";
import SmartTable from "components/Common/Tables";
import { ColumnsType } from "antd/es/table";

const ApplicationDocuments = () => {
  useDocumentTitle("Workflow Documents");

  const { applicationId } = useParams();

  const { data, loading } = useQuery<GetDeliverableDocumentsQuery>(Api.Document.GetAll(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 7);
    },
  });

  const [searchText, setSearchText] = useState("");

  if (loading) {
    return <LoadingSpinner />;
  }

  const documents: DeliverableDocumentNode | any = data?.allDeliverableDocuments;
  console.log({ Documents: documents });

  const sortedDocuments = [...documents].sort((a, b) => a?.name.localeCompare(b?.name));

  const searchData = sortedDocuments.filter((doc) =>
    doc.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  const segmentedOptions = [
    {
      label: "Draft",
      value: "draft",
      icon: <FormOutlined style={{ color: "#FAAD14" }} />,
    },
    {
      label: "Completed",
      value: "completed",
      icon: <CheckCircleTwoTone twoToneColor="#51C419" />,
      disabled: true,
    },
    {
      label: "Evidence",
      value: "evidence",
      icon: <MonitorOutlined style={{ color: "#rgb(24,144,255)" }} />,
      disabled: true,
    },
  ];

  const handleSearch = (input: string) => {
    setSearchText(input);
  };

  const columns: ColumnsType<DeliverableDocumentNode> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name, record, index) => (
        <>
          <Avatar
            style={{ background: avatarColors[index], fontWeight: "bold" }}
            shape="square"
            alt={record.name}
          >
            {createDocAcronym(record.name)}
          </Avatar>
          <span style={{ paddingLeft: 10 }}>{name}</span>
        </>
      ),
    },
    {
      title: "Form Number",
      dataIndex: "formNumber",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (text) => (!text || text.length < 1 ? "None" : undefined),
    },
    {
      title: "Updated",
      dataIndex: "revisionDate",
      render: (date) => (
        <div style={{ whiteSpace: "nowrap" }}>
          {date ? new Date(date).toLocaleDateString() : ""}
        </div>
      ),
    },
    // {
    //   title: "Completion",
    //   dataIndex: "completion",
    //   width: "10%",
    //   render: () => <Progress percent={0} size="small" />,
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "5%",
      render: (_, doc: any) => (
        <Space>
          <Link
            style={{ color: "#FFFFFF" }}
            to={`generate/${doc.documentId}`}
            state={{
              document: doc,
              documentId: doc.documentId,
              id: doc.id,
              applicationId,
            }}
          >
            <Button type="primary" icon={<DownloadOutlined />}>
              Download
            </Button>
          </Link>
          <Link
            style={{ color: "#FFFFFF" }}
            to={doc.documentId}
            state={{
              document: doc,
              documentId: doc.documentId,
              id: doc.id,
              applicationId,
            }}
          >
            <Button icon={<EyeOutlined />} type="primary">
              View
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <CustomPageHeader title="Workflow Documents" icon={<FileWordTwoTone />} />

      <Card className="shadowed-static">
        <Row justify="space-between">
          <Col>
            <Segmented options={segmentedOptions} />
          </Col>
          <Col>
            <Input.Search
              onSearch={handleSearch}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchText}
              placeholder="Search for documents"
              style={{ width: 550 }}
              enterButton
            />
          </Col>
        </Row>
        <Divider />
        <Row align="middle" style={{ textAlign: "left" }}>
          <Col span={24}>
            <SmartTable
              data={searchData}
              columns={columns}
              loading={loading}
              rowKey={(doc) => doc.id}
              size="middle"
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

const avatarColors = [
  "#096dd9",
  "#237804",
  "#722ed1",
  "#006d75",
  "#08979c",
  "#F5222D",
  "#ad4e00",
];

function createDocAcronym(inputString: string): string {
  // Replace hyphens and underscores with spaces
  const words = inputString.replace(/[-_]/g, " ").split(" ");

  // Use regex to extract the first letter from each word
  const acronym = words
    .map((word) => {
      const match = word.match(/^\w/);
      return match ? match[0] : "";
    })
    .join("");

  return acronym.slice(0, 3).toUpperCase();
}

export default ApplicationDocuments;
