// import { useAssignmentValue } from "../useFormAssignment";
import { QuestionType } from "types";
import { json } from "stream/consumers";
import { useDocument } from "../DocumentEditor";

/**
 * * if editable is true, then field stuff comes from appFields, else comes from doc.variableFields
 */

interface Props {
  formId: string;
  fieldType: QuestionType;
  state: any;
  dispatch: any;
  editable: boolean;
  fieldName: string;
  borderless: boolean;
  style: any;
  pageNumber?: Number;
}

const EditableField: React.FC<Props> = (props) => {
  const {
    formId,
    fieldType = "FULFILL_TEXT",
    state,
    dispatch,
    editable,
    fieldName,
    borderless = false,
    style,
    pageNumber,
  } = props;

  useDocument({
    page_number: pageNumber,
    field_name: fieldName,
    field_type: fieldType,
  });
  
  const fields = state?.document?.variableFields;
  const field = fields?.find((f) => f.fieldName === fieldName);
  const fieldId = field?.id;

  // only when editable is true
  const answers = state?.fieldAnswers;
  const answerObj = answers?.find((a) => a.fieldname === fieldName);
  const fieldValue = answerObj?.applicationAnswer?.answer; // is answerText ever used?

  const handleClick = () => {
    if (!editable) return;

    dispatch({
      type: "SET_ASSIGNMENT",
      state: {
        formId: formId,
        fieldId: fieldId,
        questionType: fieldType,
        showAnswers: false,
      },
    });
  };

  const missingAssignment = !field && "missingAssignment";
  const selectedAssignment =
    state?.fieldId && state?.fieldId === fieldId && "selectedAssignment";
  const isAssigned = field?.answer !== null ? "savedAssignment" : "unsavedAssignment";

  const mismatchedTypes = fieldType !== field?.fieldType && "mismatchTypes";
  const styleId = missingAssignment || selectedAssignment || mismatchedTypes || isAssigned;

  const styles: any = {};
  if (borderless) styles.border = "none";
  if (fieldValue) {
    styles.fontSize = 11;
    styles.paddingLeft = 4;
  }

  return (
    <div
      style={{ ...styles, ...style }}
      className="documentInput"
      id={editable ? styleId : ""}
      onClick={handleClick}
    >
      {(!editable && fieldValue) || ""}
    </div>
  );
};

export default EditableField;
