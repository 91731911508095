import EditableField from "../../../Components/EditableField";
import { FieldWrapper } from ".";

export const P1 = (props) => {
  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <div className="ctpDocument">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ textAlign: "center", fontSize: 28, padding: "50px 0 0px", width: "50%" }}
        >
          <h1 style={{ marginBottom: 0, lineHeight: "40px" }}>
            Annotated Outline Certification Test Plan
          </h1>
        </div>
      </div>

      <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div className="decimalList">
          <ol>
            <li>
              Introduction
              <ol>
                <br />
                <li>
                  &nbsp; &nbsp; Test Purpose
                  <FieldWrapper>
                    <EditableField {...elementProps} fieldName="1_test_purpose" />
                  </FieldWrapper>
                </li>
                <li>
                  &nbsp; &nbsp; Pass/Fail Criteria
                  <FieldWrapper height={170}>
                    <EditableField {...elementProps} fieldName="1_pass_fail" />
                  </FieldWrapper>
                </li>
                <li>
                  &nbsp; &nbsp; Test System Configuration
                  <FieldWrapper height={170}>
                    <EditableField {...elementProps} fieldName="1_test_system_configuration" />
                  </FieldWrapper>
                  <ol>
                    <li>Hardware</li>
                    <li>Software</li>
                    <li>Database</li>
                    <li>Other Configuration Parameters</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
