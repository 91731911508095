import { useDocument } from "../DocumentEditor";
import { useAssignmentValue } from "../useFormAssignment";

const EditableCheckbox = (props) => {
  const { applicationId, formId, fieldName, state, dispatch, editable, label, style, contentStyle, pageNumber } = props;

  useDocument({
    page_number: pageNumber,
    field_name: fieldName,
    field_type: "MULTIPLE_CHOICE_PICK_MANY",
  });

  const fields = state?.document?.variableFields;
  const field = fields?.find((f) => f.fieldName === fieldName);
  const fieldId = field?.id;

  // only when editable true
  const answerObj = state?.fieldAnswers?.find((a) => a.fieldname === fieldName);

  const assignment = useAssignmentValue({
    applicationId,
    formId,
    editable,
    fieldIds: [fieldId],
  });

  if (assignment) console.log("EditableCheckboxAssignment", { Assignment: assignment });

  const checked = answerObj?.applicationAnswer?.isSaved;

  const handleClick = (e) => {
    e.preventDefault();
    if (!editable) return;
    dispatch({
      type: "SET_ASSIGNMENT",
      state: {
        formId: formId,
        fieldId: fieldId,
        questionType: "MULTIPLE_CHOICE_PICK_MANY",
        showAnswers: true,
      },
    });
  };

  const fieldType = "MULTIPLE_CHOICE_PICK_MANY"

  const missingAssignment = !field && "missingAssignment";
  const selectedAssignment =
    state?.fieldId && state?.fieldId === fieldId && "selectedAssignment";
  const isAssigned = field?.answer !== null ? "savedAssignment" : "unsavedAssignment";

  const mismatchedTypes = fieldType !== field?.fieldType && "mismatchTypes"
  const styleId = missingAssignment || selectedAssignment || mismatchedTypes || isAssigned;

  if (label === undefined) {
    return (
      <>
        <input
          readOnly
          type="checkbox"
          className="documentCheckbox"
          id={editable ? styleId : ""}
          checked={checked || false}
          onClick={handleClick}
        />
      </>
    )
  }


  return (
    <>
      <div style={{ display: 'flex', width: '100%', ...style }}>
        <input
          readOnly
          type="checkbox"
          className="documentCheckbox"
          id={editable ? styleId : ""}
          checked={checked || false}
          onClick={handleClick}
        />
        <div style={{ display: 'flex', marginLeft: '4px', width: '100%', alignItems: 'center', ...contentStyle }}>
          {label}
        </div>
      </div>
    </>

  );
};
export default EditableCheckbox;
