import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Button, Space } from "antd";

export const P9 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props?.pageNumber
  };

  const OMarking = {
    label: "Select Marking",
    value: "overlay_marking",
  };

  return (
    <>
      <div style={{ display: 'grid', placeItems: "baseline", height: '100%' }}>
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'60%'} />
              <col span={1} />
            </colgroup>

            <thead>
              <tr>
                <th className="title" colSpan={3}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={OMarking} style={{ border: 'none' }} />
                    </div>
                    <div>
                      12. Overlays
                    </div>
                  </div>

                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>12A. Intelligence Overlay:</b> Does the System process, store, or transmit<br />
                  Intelligence, Surveillance, or Reconnaissance (ISR)?<br />
                  <i>Ref (r)</i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="12a_overlays_yes"
                      label={<><b>Yes</b> (Intelligence Overlay required)</>} />
                    <EditableRadio {...elementProps} fieldName="12a_overlays_no"
                      label={<b>No</b>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>12B. Cross Domain Solution (CDS) Overlay</b>: Will you implement,<br />
                  manage, or maintain a CDS?<br />
                  <i>Ref (s)</i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="12b_overlays_yes"
                      label={<><b>Yes</b> (CDS Overlay required)</>} />
                    <EditableRadio {...elementProps} fieldName="12b_overlays_no"
                      label={<b>No</b>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>12C. Nuclear Command, Control & Communications (NC3)<br />
                    Overlay:</b> Does the System store, process, or transmit NC3 data?<br />
                  <i>Refs: (r) & (t)</i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="12c_overlays_yes"
                      label={<><b>Yes</b> (NC3 & Intelligence Overlays required)</>} />
                    <EditableRadio {...elementProps} fieldName="12c_overlays_no"
                      label={<b>No</b>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>12D. Space Platform Overlay</b>: Is the System or (subsystem) a space<br />
                  platform (as defined in Committee on National Security Systems<br />
                  Instruction (CNSSI) 1253F, Attachment 2) and unmanned?<br />
                  <i>Ref: (u)</i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="12d_overlays_yes"
                      label={<><b>Yes</b> (Space Platform Overlay required)</>} />
                    <EditableRadio {...elementProps} fieldName="12d_overlays_no"
                      label={<b>No</b>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>12E. Classified Information Overlay:</b> Does the System store, process, or<br />
                  transmit classified information?<br />
                  <i>Ref: (v)</i>
                </td>
                <td>
                  <Space direction="vertical" style={{ marginLeft: '20px' }}>
                    <EditableRadio {...elementProps} fieldName="12e_overlays_yes"
                      label={<><b>Yes</b> (Classified Information Overlay required)</>} />
                    <EditableRadio {...elementProps} fieldName="12e_overlays_no"
                      label={<b>No</b>}
                    />
                  </Space>
                </td>
              </tr>

              <tr>
                <td className="label" style={{ fontWeight: 'normal' }}>
                  <b>12F. DOD Functional Mission / Capability Specific Overlay</b>: Is<br />
                  your System required to execute an organizational mission or functionspecific<br />
                  capability (e.g., Financial, Acquisition)?<br />
                  <i>Ref: (w)</i>
                </td>
                <td>
                  <div style={{ width: '100%' }}>
                    <Space direction="vertical" style={{ marginLeft: '20px', width: '90%' }}>
                      <EditableRadio {...elementProps} fieldName="12f_overlays_yes"
                        label={<div><b>Yes</b> (Specify Overlay:)</div>}
                        extra={<EditableField {...elementProps} fieldName="12f_overlays_yes_other" style={{ minHeight: '30px' }} />}
                        contentStyle={{ flexDirection: 'column', alignItems: 'start' }}
                      />
                      <EditableRadio {...elementProps} fieldName="12f_overlays_no"
                        label={<b>No</b>}
                      />
                    </Space>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "80%",
            textAlign: "end",
            padding: 5,
            alignSelf: 'end',
            justifySelf: 'end'
          }}
        >
          <Button size="small" style={{ outline: '4px solid black', borderRadius: 0 }}>
            Reset Section 12
          </Button>
        </div>
      </div>
    </>
  );
};
