import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";
import { Row, Col, Button } from "antd";

export const P3 = (props) => {
  const { state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
    pageNumber: props?.pageNumber
  };


  const SCMarking = {
    label: "Select Marking",
    value: "sc_classification_marking",
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "baseline", height: '100%' }}>
        <div className="page-table">
          <table border={1}>
            <colgroup>
              <col span={1} width={'50%'} />
              <col span={1} width={'50%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="title" colSpan={2}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}>
                    <div style={{ paddingLeft: '10px' }}>
                      <ClassificationSelect elementProps={elementProps} config={SCMarking} style={{ border: 'none' }} />
                    </div>
                    <div>3.Security Classification <i>Refs: (g) & (h)</i></div>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td className="label">
                  3A. Highest Classification of Information Processed
                </td>
                <td>
                  <div style={{ display: 'flex', rowGap: '4px', flexDirection: 'column', alignItems: 'start', paddingLeft: 10 }}>
                    <EditableRadio {...elementProps} fieldName="security_classification_3a_tscsi" label={<b>Top Secret/Sensitive Compartmented Information (TS/SCI)</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3a_topsecret" label={<b>Top Secret (TS)</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3a_secret" label={<b>Secret (S)</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3a_confidential" label={<b>Confidential</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3a_cui" label={<b>Controlled Unclassified Information (CUI)</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3a_unclassified" label={<b>Unclassified (U)</b>} />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  3B. Are there special handling caveats (e.g., dissemination<br />
                  control or releasability) for the information processed? <br />
                  <i>Refs: (i) & (j)</i>
                </td>
                <td>
                  <div style={{ display: 'flex', rowGap: '4px', flexDirection: 'column', alignItems: 'start', paddingLeft: 10 }}>
                    <EditableRadio {...elementProps} fieldName="security_classification_3b_noforn" label={<b>NOFORN</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3b_nato" label={<b>NATO</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3b_fvey" label={<b>FIVE EYES (FVEY)</b>} />
                    <div style={{ width: '100%', display: 'flex' }}>
                      <EditableRadio {...elementProps}
                        fieldName="security_classification_3b_other"
                        label={<b>Other</b>}
                        extra={<EditableField {...elementProps} fieldName="security_classification_3b_other_text" style={{ marginLeft: '40px' }} />}
                      />
                    </div>
                    <EditableRadio {...elementProps} fieldName="security_classification_3b_none" label={<b>None</b>} />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  3C. Does the system process SAP data?<br />
                  <i>Refs: (k) & (l)</i>
                </td>
                <td>
                  <div style={{ display: 'flex', rowGap: '4px', flexDirection: 'column', alignItems: 'start', paddingLeft: 10 }}>
                    <EditableRadio {...elementProps} fieldName="security_classification_3c_yes" label={<b>Yes</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3c_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  3D. Any Foreign National Users?<br />
                  <i>Refs: (j) & (m)</i>
                </td>
                <td>
                  <div style={{ display: 'flex', rowGap: '4px', flexDirection: 'column', alignItems: 'start', paddingLeft: 10 }}>
                    <EditableRadio {...elementProps} fieldName="security_classification_3d_yes" label={<b>Yes</b>} />
                    <EditableRadio {...elementProps} fieldName="security_classification_3d_no" label={<b>No</b>} />

                  </div>
                </td>
              </tr>

              <tr>
                <td className="label">
                  3E. List applicable Security Classification Guide (SCG)
                </td>
                <td>
                  <EditableField {...elementProps} fieldName="security_classification_3e" style={{ minHeight: '80px' }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "80%",
            textAlign: "end",
            padding: 5,
            alignSelf: 'end',
            justifySelf: 'end'
          }}
        >
          <Button size="small" style={{ outline: '4px solid black', borderRadius: 0 }}>
            Reset Section 3
          </Button>
        </div>
      </div>
    </>
  );
};
