import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { ClassificationSelect } from "..";

export const P16 = (props) => {
  const elementProps = {
    // applicationId,
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <section>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                border: "1px solid black",
                borderTop: "1px solid black",
                backgroundColor: "lightgrey",
              }}
            >
              <div style={{ flex: 1 }}>
                <b style={{ fontSize: 14 }}>Classification Information (Section 13)</b>
              </div>
              <div style={{ flex: 1, borderTop: "1px solid black", textAlign: "left" }}>
                <b style={{ fontSize: 12, padding: "0 5px" }}>
                  Highest Data Classification Processed
                </b>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
                borderTop: "none",
                borderBottom: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "10px 0",
                }}
              >
                <div
                  style={{
                    width: "300px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_highest_data_processed_top_secret"
                        // fieldId={document.variableFields[456]?.id}
                      />
                    </span>
                    <span>Top Secret</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_highest_data_processed_secret"
                        // fieldId={document.variableFields[457]?.id}
                      />
                    </span>
                    <span>Secret</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_highest_data_processed_confidential"
                        // fieldId={document.variableFields[458]?.id}
                      />
                    </span>
                    <span>Confidential</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_highest_data_processed_controlled_unclassified_information"
                        // fieldId={document.variableFields[459]?.id}
                      />
                    </span>
                    <span>Controlled Unclassified Information (CUI)</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_highest_data_processed_unclassified"
                        // fieldId={document.variableFields[460]?.id}
                      />
                    </span>
                    <span>Unclassified</span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  borderTop: "1px solid black",
                  borderBottom: "none",
                  backgroundColor: "lightgrey",
                }}
              >
                <div style={{ flex: 1, textAlign: "left" }}>
                  <b style={{ fontSize: 12, padding: "0 5px" }}>
                    Dissemination Control/Releasability
                  </b>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "10px 0",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "300px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_dissemination_control_noforn"
                        // fieldId={document.variableFields[461]?.id}
                      />
                    </span>
                    <span>NOFORN</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_dissemination_control_nato"
                        // fieldId={document.variableFields[462]?.id}
                      />
                    </span>
                    <span>NATO</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_dissemination_control_five_eyes"
                        // fieldId={document.variableFields[463]?.id}
                      />
                    </span>
                    <span>FIVE EYES (FVEY)</span>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_dissemination_control_other"
                        // fieldId={document.variableFields[464]?.id}
                      />
                    </span>
                    <div style={{ width: "100%", marginRight: 5 }}>
                      Other
                      <div style={{ width: "100%" }}>
                        <EditableField
                          {...elementProps}
                          fieldName="ci_dissemination_control_other_additional"
                          // fieldId={document.variableFields[465]?.id}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_dissemination_control_none"
                        // fieldId={document.variableFields[466]?.id}
                      />
                    </span>
                    <span>None</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style={{ paddingTop: "50px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                border: "1px solid black",
                borderTop: "1px solid black",
                backgroundColor: "lightgrey",
              }}
            >
              <div style={{ flex: 1, textAlign: "left" }}>
                <b style={{ fontSize: 12, padding: "0 5px", margin: "10px 0" }}>
                  Proposed System (Section 8)
                </b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
                borderTop: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "10px 0",
                }}
              >
                <b
                  style={{
                    width: "100%",
                    padding: "0 5px",
                    textAlign: "left",
                  }}
                >
                  Information Systems/PIT Systems
                </b>
                <div
                  style={{
                    width: "300px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_proposed_system_is_pit_systems_enclave"
                        // fieldId={document.variableFields[467]?.id}
                      />
                    </span>
                    <span>Enclave</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_proposed_system_is_pit_systems_major_application"
                        // fieldId={document.variableFields[468]?.id}
                      />
                    </span>
                    <span>Major Application</span>
                  </div>
                  <div>
                    <span style={{ margin: "0 5px" }}>
                      <EditableRadio
                        {...elementProps}
                        fieldName="ci_proposed_system_is_pit_systems_pit_systems"
                        // fieldId={document.variableFields[469]?.id}
                      />
                    </span>
                    <span>PIT System(s)</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "stretch",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    border: "1px solid black",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <b>PIT</b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_pit_pit_products"
                            // fieldId={document.variableFields[472]?.id}
                          />
                        </span>
                        <span>PIT Product(s)</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_pit_pit_systems"
                            // fieldId={document.variableFields[473]?.id}
                          />
                        </span>
                        <span>PIT Subsystem(s)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid black",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <b>System Services</b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_system_services_external"
                            // fieldId={document.variableFields[474]?.id}
                          />
                        </span>
                        <span>External</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_system_services_internal"
                            // fieldId={document.variableFields[475]?.id}
                          />
                        </span>
                        <span>Internal</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid black",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <b>System Products</b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_system_products_application"
                            // fieldId={document.variableFields[476]?.id}
                          />
                        </span>
                        <span>Application</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_system_products_hardware"
                            // fieldId={document.variableFields[477]?.id}
                          />
                        </span>
                        <span>Hardware</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_system_products_software"
                            // fieldId={document.variableFields[478]?.id}
                          />
                        </span>
                        <span>Software</span>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "stretch",
                }}
              >
                <div
                  style={{
                    flex: 1.4,
                    width: "100%",
                    border: "1px solid black",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <b>Overlays (Sections 10 & 12)</b>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_intelligence"
                            // fieldId={document.variableFields[479]?.id}
                          />
                        </span>
                        <span>Intelligence</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_cross_domain_solution"
                            // fieldId={document.variableFields[480]?.id}
                          />
                        </span>
                        <span>Cross Domain Solution</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_nuclear"
                            // fieldId={document.variableFields[481]?.id}
                          />
                        </span>
                        <span>Nuclear (NC3)</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_space"
                            // fieldId={document.variableFields[482]?.id}
                          />
                        </span>
                        <span>Space</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_classified"
                            // fieldId={document.variableFields[483]?.id}
                          />
                        </span>
                        <span>Classified</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_security"
                            // fieldId={document.variableFields[484]?.id}
                          />
                        </span>
                        <span>Security</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_privacy"
                            // fieldId={document.variableFields[485]?.id}
                          />
                        </span>
                        <span>Privacy</span>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <span style={{ margin: "0 5px" }}>
                          <EditableCheckbox
                            {...elementProps}
                            fieldName="ci_proposed_system_overlays_dod_functional_mission"
                            // fieldId={document.variableFields[486]?.id}
                          />
                        </span>
                        <div style={{ width: "100%", marginRight: 5 }}>
                          DoD Functional Mission/Capability Specific
                          <div style={{ width: "100%" }}>
                            <EditableField
                              {...elementProps}
                              fieldName="ci_proposed_system_overlays_dod_functional_mission_additional"
                              // fieldId={document.variableFields[487]?.id}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1.2,
                    width: "100%",
                    border: "1px solid black",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <b>Privacy Impact Assessment Status</b>
                  </div>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_pia_status_started"
                            // fieldId={document.variableFields[488]?.id}
                          />
                        </span>
                        <span>Started</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_pia_status_partial"
                            // fieldId={document.variableFields[489]?.id}
                          />
                        </span>
                        <span>Partial</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_pia_status_completed"
                            // fieldId={document.variableFields[490]?.id}
                          />
                        </span>
                        <span>Completed</span>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_pia_status_other"
                            // fieldId={document.variableFields[491]?.id}
                          />
                        </span>
                        <div style={{ width: "100%", marginRight: 5 }}>
                          Other
                          <div style={{ width: "100%" }}>
                            <EditableField
                              {...elementProps}
                              fieldName="ci_proposed_system_pia_status_other_additional"
                              // fieldId={document.variableFields[492]?.id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    border: "1px solid black",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <div>
                      <b>Privacy</b>
                    </div>
                    <div>
                      <b>(Section 12I)</b>
                    </div>
                  </div>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_privacy_high"
                            // fieldId={document.variableFields[493]?.id}
                          />
                        </span>
                        <span>High</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_privacy_moderate"
                            // fieldId={document.variableFields[494]?.id}
                          />
                        </span>
                        <span>Moderate</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_privacy_low"
                            // fieldId={document.variableFields[495]?.id}
                          />
                        </span>
                        <span>Low</span>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div
                  style={{
                    flex: 1.2,
                    width: "100%",
                    border: "1px solid black",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <div>
                      <b>Operational Status</b>
                    </div>
                    <div>
                      <b>(Section 5)</b>
                    </div>
                  </div>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_operational_status_operational"
                            // fieldId={document.variableFields[496]?.id}
                          />
                        </span>
                        <span>Operational</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_operational_status_under_development"
                            // fieldId={document.variableFields[497]?.id}
                          />
                        </span>
                        <span>Under Development</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_operational_status_major_modification"
                            // fieldId={document.variableFields[498]?.id}
                          />
                        </span>
                        <span>Major Modification</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    border: "1px solid black",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "0 5px",
                    }}
                  >
                    <b>NSS</b>
                    <br />
                    <b>(Section 11I)</b>
                  </div>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_system_products_yes"
                            // fieldId={document.variableFields[499]?.id}
                          />
                        </span>
                        <span>Yes</span>
                      </div>
                      <div>
                        <span style={{ margin: "0 5px" }}>
                          <EditableRadio
                            {...elementProps}
                            fieldName="ci_proposed_system_system_products_no"
                            // fieldId={document.variableFields[500]?.id}
                          />
                        </span>
                        <span>No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
