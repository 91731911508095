import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import { FieldWrapper } from ".";

export const P3 = (props) => {
  const { document } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <div className="ctpDocument">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div className="fake-list-container">
          <div className="list-row-container" style={{ marginBottom: 12 }}>
            <p style={{ minWidth: 40 }}>5.2.1</p>
            <p style={{ fontWeight: 500 }}>
              <strong style={{ height: 20 }}>Test (T) Method: </strong>
              Consists of the exercise of hardware, software or operations (including
              simulators) in a specified environment such that pre-designated input produces
              known, predictable and repeatable output that can be readily observed and
              qualitatively evaluated to determine conformance to the requirements.
            </p>
          </div>

          <div className="list-label-container">
            <p className="list-label number">5.3</p>
            <p className="list-label">VTM</p>
          </div>

          <FieldWrapper height={130}>
            <EditableField {...elementProps} fieldName="3_vtm_a" />
          </FieldWrapper>
          <FieldWrapper height={200}>
            <EditableField {...elementProps} fieldName="3_vtm_b" />
          </FieldWrapper>
        </div>

        <div className="table-container" style={{ paddingLeft: 80 }}>
          <div
            style={{
              width: 144,
              border: "2px solid #000000",
              borderBottom: "1px solid #000000",
              height: 18,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ height: "40%" }}>Requirement Level</p>
          </div>
          <table className="vtm-table">
            <thead>
              <tr>
                <td style={{ width: 45 }}>Directed</td>
                <td style={{ width: 45 }}>Derived</td>
                <td style={{ width: 45 }}>Specific</td>
                <td style={{ width: 100 }}>Requirement Statement</td>
                <td style={{ width: 50 }}>Verification Level</td>
                <td style={{ width: 50 }}>Verification Method</td>
                <td style={{ width: 50 }}>Verification Location</td>
                <td style={{ width: 70 }}>Test Procedure Paragraph</td>
                <td style={{ width: 30, maxWidth: 35 }}>Pass/ Fail</td>
                <td style={{ width: 120 }}>Comments</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-container">
          <table className="control-table" style={{ marginTop: 32, width: "100%" }}>
            <thead>
              <tr>
                <td style={{ width: 20, maxWidth: 20, wordWrap: "break-word" }}>PKID</td>
                <td style={{ width: 30, minWidth: 30 }}>Control Acronym</td>
                <td style={{ width: 55, minWidth: 55 }}>Control Title</td>
                <td style={{ width: 300, minWidth: 300 }}>Control Information</td>
                <td style={{ width: 50, minWidth: 50 }}>RTB Baseline Control</td>
                <td style={{ width: 50, minWidth: 50 }}>OVL Cyber Hyg(1)</td>
                <td style={{ width: 20, minWidth: 20 }}> </td>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "top" }}>
              <tr>
                <td></td>
                <td>AC-2</td>
                <td>Account Management</td>
                <td>
                  Description: The organization: a. Identifies and selects the following types
                  of information system accounts to support organizational missions/business
                  functions: [Assignment: organization-defined information system account
                  types]; b. Assigns account managers for information system accounts; c.
                  Establishes conditions for group and role membership; d. Specifies authorized
                  users of the information system, group and role membership, and access
                  authorizations (i.e., privileges) and other attributes (as required) for each
                  account; e. Requires approvals by [Assignment: organization-defined personnel
                  or roles] for requests to create information system accounts; f. Creates,
                  enables, modifies, disables, and removes information system accounts in
                  accordance with [Assignment: organization-defined procedures or conditions];
                  g. Monitors the use of information system accounts; h. Notifies account
                  managers: 1. When accounts are no longer required; 2. When users are
                  terminated or transferred; and 3. When individual information system usage or
                  need-to-know changes; i. Authorizes access to the information system based
                  on: 1. A valid access authorization;
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
