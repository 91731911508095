import { useParams } from "react-router-dom";
import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";

export const P4 = (props) => {
  const { state } = props;

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  return (
    <>
      <div
        className="doc_pcil"
        style={{
          width: "95%",
          fontSize: 12,
        }}
      >
        <div style={{ paddingLeft: 20 }}>
          <b style={{ fontSize: 16 }}>1. Overview</b>
          <div style={{ paddingLeft: 20 }}>
            <p>
              Identifying the system’s Personally Identifiable Information (PII)
              confidentiality impact level (PCIL, pronounced like “pickle”) value is a
              follow-on step to the information system provisional security categorization
              step. Note that although the PII confidentiality impact level sounds similar, it
              is different from, and does not equate to, the impact values for the security
              objectives of confidentiality, integrity, and availability for the system
              overall, which are used to determine the security control baselines in CNSSI No.
              1253. Once the PII confidentiality impact level value is selected, it should be
              used to select the appropriate Privacy Overlay – whose controls are added to the
              previously selected security control baseline.
            </p>
          </div>
        </div>
        <div style={{ paddingLeft: 40 }}>
          <b style={{ fontSize: 16 }}>
            <span style={{ paddingRight: 10 }}>1.1 </span> Purpose
          </b>
          <ul>
            <div style={{ display: "flex" }}>
              <div style={{ whiteSpace: "nowrap" }}>To assist the</div>
              <div>
                <EditableField
                  {...elementProps}
                  fieldName="4_purpose_privacy_overlay"
                  style={{ margin: "0 5px", width: 150, height: 20 }}
                />
              </div>
              <div>team in conducting the analysis associated with determining the PII</div>
            </div>
            <div>confidentiality impact value for the Privacy Overlay.</div>
          </ul>
        </div>

        <div style={{ paddingLeft: 40 }}>
          <b style={{ fontSize: 16 }}>
            <span style={{ paddingRight: 10 }}>1.2 </span> Scope
          </b>
          <ul>
            <div style={{ display: "flex" }}>
              <div style={{ whiteSpace: "nowrap" }}>This work sheet is specific to</div>
              <div>
                <EditableField
                  {...elementProps}
                  fieldName="4_scope_privacy_overlay"
                  style={{ margin: "0 5px", width: 150, height: 20 }}
                />
              </div>
              <div>and the associated Privacy Overlay.</div>
            </div>
          </ul>
        </div>

        <div style={{ paddingLeft: 40 }}>
          <b style={{ fontSize: 16 }}>
            <span style={{ paddingRight: 10 }}>1.3 </span> Instructions
          </b>
          <ul>
            <div>
              Per DoDI 8510.01, Reference (e) is used to conduct the Privacy Overlay
              categorization analysis for the information system. Reference (e) will further
              reference (NIST SP 800-122, FIPS 199, and NIST SP 800-37).
            </div>
          </ul>
        </div>

        <div style={{ paddingLeft: 40 }}>
          <b style={{ fontSize: 16 }}>
            <span style={{ paddingRight: 10 }}>1.3 </span> Four Key Questions
          </b>
          <ul>
            <div>
              The PII Confidentiality Impact Level analysis conducted IAW reference (e) will
              answer four questions:
              <div style={{ display: "flex" }}>
                <div style={{ padding: "0 20px" }}>1.</div>
                <i>
                  Does the information system collect, use, process, store, maintain,
                  disseminate, disclose, or dispose of PII?
                </i>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ padding: "0 20px" }}>2.</div>
                <i> Does Exception of the Business Rolodex Information apply?</i>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ padding: "0 20px" }}>3. </div>
                <i>Is the PII confidentiality impact level low, moderate, or high?</i>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ padding: "0 20px" }}>4.</div>
                <i>Is your organization a covered entity or business associate under HIPAA?</i>
              </div>
              <br />
              <p>
                Once these four questions have been answered, the information system security
                manager will use the PII confidentiality impact level (PCIL – pronounced like
                “pickle”) value to select the appropriate Privacy Overlay(s) (e.g., low,
                moderate, high, and/or PHI).
              </p>
              <p>
                Per Reference (e), “Organizations should encourage close coordination among
                their chief privacy officers, senior agency officials for privacy, chief
                information officers, chief information security officers, and legal counsel
                when addressing issues related to PII.”
              </p>
            </div>
          </ul>
        </div>

        <div style={{ fontSize: 16, marginLeft: 20 }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
          <b style={{ color: "coral" }}>
            <i>
              <u>The PCIL analysis process begins in Section 2</u>
            </i>
          </b>
          <b>
            <i> (see below).</i>
          </b>
        </div>
        <div style={{ fontSize: 16, marginLeft: 20, display: "flex" }}>
          <b style={{ fontSize: 16, paddingRight: 10 }}>➤</b>
          <b>
            <i>
              Once the PCIL analysis has been completed, the Information System Security
              Manager and Program Manager should review and sign the worksheet at Appendix B,
              and forward the worksheet to the Privacy Officer for review and signature.
            </i>
          </b>
        </div>

        <br />
        <br />
        <br />
        <br />
        <ul>
          <span style={{ fontSize: 8 }}>1 </span>See section 2.2 below.
        </ul>
      </div>
    </>
  );
};
