import EditableField from "../../../Components/EditableField";
import EditableRadio from "../../../Components/EditableRadio";
import EditableCheckbox from "../../../Components/EditableCheckbox";
import EditableSelect from "../../../Components/EditableSelect";
import { AlignedEditableCheckbox, AlignedEditableRadio } from "..";
import { Row, Col } from "antd";
import { DodComponentNameSelect } from "..";

export const P1 = (props) => {
  const { document, state } = props;

  const elementProps = {
    formId: props.formId,
    state: props.state,
    dispatch: props.dispatch,
    editable: props.editable,
  };

  const fieldId = (fName) =>
    state?.document?.variableFields?.find((f) => f.fieldName === fName)?.id;

  const DodFieldNames = [
    "1_dod_comp_name_assistant_to_the_secretary_of_defense_for_homeland_defense",
    "1_dod_comp_name_assistant_secretary_of_defense_international_security_affairs",
    "1_dod_comp_name_assistant_secretary_of_defense_international_security_policy",
    "1_dod_comp_name_assistant_secretary_of_defense_legal_affairs",
    "1_dod_comp_name_assistant_secretary_of_defense_networks_and_information",
    "1_dod_comp_name_assistant_to_the_secretary_of_defense_public_affairs",
    "1_dod_comp_name_assistant_secretary_of_defense_reserve_affairs",
    "1_dod_comp_name_assistant_secretary_of_defense_special_operations_low_intensity_conflict",
    "1_dod_comp_name_assistant_secretary_of_defense_for_research_and_engineering",
    "1_dod_comp_name_assistant_secretary_of_defense_health_affairs",
    "1_dod_comp_name_counterintelligence_field_activity",
    "1_dod_comp_name_defense_acquisition_university",
    "1_dod_comp_name_defense_advanced_research_projects_agency",
    "1_dod_comp_name_defense_commissary_agency",
    "1_dod_comp_name_defense_contract_audit_agency",
    "1_dod_comp_name_defense_contract_management_agency",
    "1_dod_comp_name_defense_finance_and_accounting_service",
    "1_dod_comp_name_defense_health_agency",
    "1_dod_comp_name_defense_human_resources_activity",
    "1_dod_comp_name_defense_information_systems_agency",
    "1_dod_comp_name_defense_intelligence_agency",
    "1_dod_comp_name_defense_legal_services_agency",
    "1_dod_comp_name_defense_logistics_agency",
    "1_dod_comp_name_defense_media_activity",
    "1_dod_comp_name_defense_personnel_accounting_agency",
    "1_dod_comp_name_defense_security_cooperation_agency",
    "1_dod_comp_name_defense_security_service",
    "1_dod_comp_name_defense_technical_information_center",
    "1_dod_comp_name_defense_technology_security_administration",
    "1_dod_comp_name_defense_test_resource_management_center",
    "1_dod_comp_name_defense_threat_reduction_agency",
    "1_dod_comp_name_department_of_defense_chief_information_officer",
    "1_dod_comp_name_department_of_defense_education_activity",
    "1_dod_comp_name_department_of_defense_inspector_general",
    "1_dod_comp_name_department_of_the_navy",
    "1_dod_comp_name_department_of_the_navy_united_states_marine_corps",
    "1_dod_comp_name_deputy_chief_management_officer",
    "1_dod_comp_name_deputy_undersecretary_of_defense_for_installations_and_environmental_security",
    "1_dod_comp_name_director_administration",
    "1_dod_comp_name_director_cost_assessment_and_program_evaluation",
    "1_dod_comp_name_director_force_transformation",
    "1_dod_comp_name_director_net_assessment",
    "1_dod_comp_name_director_operational_test_and_evaluation",
    "1_dod_comp_name_do_d_business_enterprise",
    "1_dod_comp_name_do_d_force_protection",
    "1_dod_comp_name_general_counsel_of_the_department_of_defense",
    "1_dod_comp_name_inter_american_defense_board",
    "1_dod_comp_name_joint_improvised_explosive_device_defeat_organization",
    "1_dod_comp_name_joint_program_executive_office_for_joint_tactical_radio_system",
    "1_dod_comp_name_joint_staff",
    "1_dod_comp_name_missile_defense_agency",
    "1_dod_comp_name_national_defense_university",
    "1_dod_comp_name_national_geospatial_intelligence_agency",
    "1_dod_comp_name_national_guard_bureau",
    "1_dod_comp_name_national_reconnaissance_office",
    "1_dod_comp_name_national_security_agency",
    "1_dod_comp_name_north_american_aerospace_defense_command",
    "1_dod_comp_name_office_of_economic_adjustment",
    "1_dod_comp_name_office_of_military_commissions",
    "1_dod_comp_name_office_of_secretary_of_defense",
    "1_dod_comp_name_office_of_the_deputy_assistant_secretary_of_defense_for_counter_narcotics",
    "1_dod_comp_name_office_of_the_secretary_of_defense_chief_information_officer",
    "1_dod_comp_name_outside_do_d",
    "1_dod_comp_name_pentagon_force_protection_agency",
    "1_dod_comp_name_u_s_coast_guard",
    "1_dod_comp_name_u_s_mission_to_nato",
    "1_dod_comp_name_under_secretary_of_defense_comptroller_chief_financial_officer",
    "1_dod_comp_name_under_secretary_of_defense_for_acquisition_technology_and_logistics",
    "1_dod_comp_name_under_secretary_of_defense_for_intelligence",
    "1_dod_comp_name_under_secretary_of_defense_for_personnel_and_readiness",
    "1_dod_comp_name_under_secretary_of_defense_for_policy",
    "1_dod_comp_name_united_states_africa_command_usafricom",
    "1_dod_comp_name_united_states_air_force",
    "1_dod_comp_name_united_states_army",
    "1_dod_comp_name_united_states_central_command_uscentcom",
    "1_dod_comp_name_united_states_cyber_command_uscybercom",
    "1_dod_comp_name_united_states_european_command_useucom",
    "1_dod_comp_name_united_states_forces_korea",
    "1_dod_comp_name_united_states_joint_forces_command",
    "1_dod_comp_name_united_states_northern_command_usnorthcom",
    "1_dod_comp_name_united_states_pacific_command_uspacom",
    "1_dod_comp_name_united_states_southern_command_ussouthcom",
    "1_dod_comp_name_united_states_space_command_usspacecom",
    "1_dod_comp_name_united_states_special_operations_command_ussocom",
    "1_dod_comp_name_united_states_strategic_command_usstratcom",
    "1_dod_comp_name_united_states_transportation_command_ustranscom",
    "1_dod_comp_name_us_army_nato",
    "1_dod_comp_name_washington_headquarters_service",
    "1_dod_comp_name_white_house_communications_agency",
    "1_dod_comp_name_if_other_enter_the_component_name_in_the_box_below"
]

  const DodNameConfig = {
    label: "Select One",
    value: "dod_name_config",
    fieldNames: DodFieldNames,
    fieldIds:DodFieldNames.map((obj) => fieldId(obj))
  };

  return (
    <>
      <div style={{ textAlign: "center", fontSize: 14 }}>
        <b>PRIVACY IMPACT ASSESSMENT (PIA)</b>
      </div>
      <div style={{ marginTop: 20 }}>
        <b>PRESCRIBING AUTHORITY:</b> DoD Instruction 5400.16, "DoD Privacy Impact Assessment
        (PIA) Guidance". Complete this form for Department of Defense (DoD) information systems
        or electronic collections of information (referred to as an "electronic collection" for
        the purpose of this form) that collect, maintain, use, and/or disseminate personally
        identifiable information (PII) about members of the public, Federal employees,
        contractors, or foreign nationals employed at U.S. military facilities internationally.
        In the case where no PII is collected, the PIA will serve as a conclusive determination
        that privacy requirements do not apply to system.
      </div>
      <div style={{ marginTop: 20 }}>
        <b>1. DOD INFORMATION SYSTEM/ELECTRONIC COLLECTION NAME:</b>
        <div
          style={{
            width: "100%",
            fontSize: 14,
            marginBottom: 2,
          }}
        >
          <EditableField {...elementProps} fieldName="1_dod_information_collection_name" />
        </div>
      </div>
      <Row gutter={4} style={{ marginBottom: 2}}>
        <Col span={18}>
          <b>2. DOD COMPONENT NAME:</b>
          <div
            style={{
              width: "100%",
              fontSize: 14,
              marginBottom: 2,
            }}
          >
              <DodComponentNameSelect
                style={{width:'100%', marginBottom: 2, height: "20px", fontSize: 10,
                textAlign: "center",
              }}
                elementProps={elementProps} 
                config={DodNameConfig} />

          </div>
        </Col>
        <Col span={6}>
          <b>3. PIA APPROVAL DATE:</b>
          <div
            style={{
              width: "100%",
              fontSize: 14,
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            <EditableField
              {...elementProps}
              fieldName="1_pia_approval_date"
              fieldType="FULFILL_DATE"
            />
          </div>
        </Col>
      </Row>
      <div
        style={{
          width: "100%",
          fontSize: 14,
        }}
      >
        <EditableField {...elementProps} fieldName="1_bellow_dod_component_name" />
      </div>
      <div style={{ textAlign: "center", fontWeight: "bold", marginTop: 5 }}>
        <u>SECTION 1: PII DESCRIPTION SUMMARY (FOR PUBLIC RELEASE)</u>
      </div>
      <div style={{ marginTop: 10 }}>
        <b>a. The PII is:</b>
        <i>(Check one. Note: foreign nationals are included in general public.)</i>
        <Row gutter={16} align="middle">
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_from_general_public"
              text="From members of the general public"
            />
          </Col>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_from_federal"
              text="From Federal employees and/or Federal contractors"
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle" style={{ marginTop: 10 }}>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_from_both"
              text="From both members of the general public and Federal employees and/or Federal contractors"
            />
          </Col>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_not_collected"
              text="Not Collected (if checked proceed to Section 4) employees and/or Federal contractors"
            />
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 10 }}>
        <b>b. The PII is in a:</b> <i>(Check one)</i>
        <Row gutter={16} align="middle" style={{ marginTop: 5 }}>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_new_dod_system"
              text="New DoD Information System"
            />
          </Col>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_new_electronic_collection"
              text="New Electronic Collection"
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle" style={{ marginTop: 10 }}>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_existing_dod"
              text="Existing DoD Information System"
            />
          </Col>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_existing_electronic_collection"
              text="Existing Electronic Collection"
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle" style={{ marginTop: 10 }}>
          <Col span={12}>
            <AlignedEditableRadio
              {...elementProps}
              fieldName="1_pii_is_modified_dod"
              text="Significantly Modified DoD Information System"
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 20, fontWeight: "bold" }}>
        c. Describe the purpose of this DoD information system or electronic collection and
        describe the types of personal information about individuals collected in the system.
        <div
          style={{
            width: "100%",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          <EditableField {...elementProps} fieldName="1_purpose_of_dod" />
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <b>d. Why is the PII collected and/or what is the intended use of the PII?</b>{" "}
        <i>
          (e.g., verification, identification, authentication, data matching, mission-related
          use, administrative use)
        </i>
        <div
          style={{
            width: "100%",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          <EditableField {...elementProps} fieldName="1_why_is_pii_collected" />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <Row align="middle" gutter={16}>
          <Col>
            <b>
              e. Do individuals have the opportunity to object to the collection of their PII?
            </b>
          </Col>
          <Col>
            <AlignedEditableRadio {...elementProps} fieldName="1_pii_object_yes" text="Yes" />
          </Col>
          <Col>
            <AlignedEditableRadio {...elementProps} fieldName="1_pii_object_no" text="No" />
          </Col>
        </Row>
        <div style={{ marginTop: 10 }}>
          (1) If "Yes," describe the method by which individuals can give or withhold their
          consent.
        </div>{" "}
        <div style={{ marginTop: 10 }}>
          (2) If "No," state the reason why individuals cannot give or withhold their consent.
        </div>
        <div
          style={{
            width: "100%",
            fontSize: 14,
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <EditableField
            {...elementProps}
            fieldName="1_opportunity_to_object_text"
            height={45}
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <b>
          g. When an individual is asked to provide PII, a Privacy Act Statement (PAS) and/or a
          Privacy Advisory must be provided.
        </b>{" "}
        <i>(Check as appropriate and provide the actual wording.)</i>
        <div style={{ marginTop: 5 }}>
          <Row gutter={100}>
            <Col>
              <AlignedEditableCheckbox
                {...elementProps}
                fieldName="1_privacy_act_statement"
                text="Privacy Act Statement"
              />
            </Col>
            <Col>
              <AlignedEditableCheckbox
                {...elementProps}
                fieldName="1_privacy_advisory"
                text="Privacy Advisory"
              />
            </Col>
            <Col>
              <AlignedEditableCheckbox
                {...elementProps}
                fieldName="1_not_applicable"
                text="Not Applicable"
              />
            </Col>
          </Row>
        </div>
        <div
          style={{
            width: "100%",
            fontSize: 14,
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <EditableField
            {...elementProps}
            fieldName="1_privacy_must_be_provided_text"
            height={45}
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <b>
          h. With whom will the PII be shared through data exchange, both within your DoD
          Component and outside your Component?
        </b>{" "}
        <i> (Check all that apply)</i>
        <Row gutter={16} align="middle" style={{ marginTop: 10 }}>
          <Col span={11} push={1}>
            <AlignedEditableCheckbox
              {...elementProps}
              fieldName="1_within_the_dod_component"
              text="Within the DOD Component"
            />
          </Col>
          <Col span={3}>Specify.</Col>
          <Col span={10}>
            <div
              style={{
                width: "100%",
                fontSize: 14,
                textAlign: "center",
              }}
            >
              <EditableField {...elementProps} fieldName="1_pii_shared_text" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
