import moment from "moment";
import EditableField from "../../Components/EditableField";
import EditableRadio from "../../Components/EditableRadio";
import EditableCheckbox from "../../Components/EditableCheckbox";
import EditableSelect from "../../Components/EditableSelect";
import { Typography, Row, Col, Space } from "antd";

import pages from "./Pages";
import { lowerCase } from "lodash";
export const PIAPages = pages;

export const PIAHeader = () => {
  return (
    <Row justify="space-between">
      <div style={{ marginTop: 20 }}></div>
    </Row>
  );
};

export const PIAFooter = ({ state, pageNumber }) => {
  const style = {
    paddingTop: 5,
    fontSize: 11,
  };

  const document = state?.document;

  return (
    <Row justify="space-between">
      <Col span={8}>
        <p style={style}>
          DD FORM {document?.formNumber}, {moment(document?.revisionDate).format("ll")}
        </p>
      </Col>
      <Col span={8}>
        <center style={style}>PREVIOUS EDITION IS OBSOLETE.</center>
      </Col>
      <Col span={8}>
        <p style={{ ...style, textAlign: "right" }}>
          Page: {pageNumber} of {document?.numberOfPages}
        </p>
      </Col>
    </Row>
  );
};

export const AlignedEditableCheckbox = (props) => (
  <Row>
    <Space>
      <EditableCheckbox {...props} />
      <Typography.Text>{props.text}</Typography.Text>
    </Space>
  </Row>
);

export const AlignedEditableRadio = (props) => (
  <Row gutter={8}>
    <Col span={1.5}>
      <EditableRadio {...props} />
    </Col>
    <Col span={20}>
      <Typography.Text>{props.text}</Typography.Text>
    </Col>
  </Row>
);

export const DodComponentNameSelect = ({ elementProps, config, style = {} }) => {
  const labels = [
    "Assistant to the Secretary of Defense for Homeland Defense",
    "Assistant Secretary of Defense - International Security Affairs",
    "Assistant Secretary of Defense - International Security Policy",
    "Assistant Secretary of Defense - Legal Affairs",
    "Assistant Secretary of Defense - Networks and Information",
    "Assistant to the Secretary of Defense - Public Affairs",
    "Assistant Secretary of Defense - Reserve Affairs",
    "Assistant Secretary of Defense - Special Operations/Low Intensity Conflict",
    "Assistant Secretary of Defense for Research and Engineering",
    "Assistant Secretary of Defense/Health Affairs",
    "Counterintelligence Field Activity",
    "Defense Acquisition University",
    "Defense Advanced Research Projects Agency",
    "Defense Commissary Agency",
    "Defense Contract Audit Agency",
    "Defense Contract Management Agency",
    "Defense Finance and Accounting Service",
    "Defense Health Agency",
    "Defense Human Resources Activity",
    "Defense Information Systems Agency",
    "Defense Intelligence Agency",
    "Defense Legal Services Agency",
    "Defense Logistics Agency",
    "Defense Media Activity",
    "Defense Personnel Accounting Agency",
    "Defense Security Cooperation Agency",
    "Defense Security Service",
    "Defense Technical Information Center",
    "Defense Technology Security Administration",
    "Defense Test Resource Management Center",
    "Defense Threat Reduction Agency",
    "Department of Defense - Chief Information Officer",
    "Department of Defense Education Activity",
    "Department of Defense Inspector General",
    "Department of the Navy",
    "Department of the Navy/United States Marine Corps",
    "Deputy Chief Management Officer",
    "Deputy Undersecretary of Defense for Installations and Environmental Security",
    "Director, Administration",
    "Director, Cost Assessment and Program Evaluation",
    "Director, Force Transformation",
    "Director, Net Assessment",
    "Director, Operational Test and Evaluation",
    "DoD Business Enterprise",
    "DoD Force Protection",
    "General Counsel of the Department of Defense",
    "Inter-American Defense. Board",
    "Joint Improvised Explosive Device Defeat Organization",
    "Joint Program Executive Office for Joint Tactical Radio System",
    "Joint Staff",
    "Missile Defense Agency",
    "National Defense University",
    "National Geospatial-Intelligence Agency",
    "National Guard Bureau",
    "National Reconnaissance Office",
    "National Security Agency",
    "North American Aerospace Defense Command",
    "Office of Economic Adjustment",
    "Office of Military Commissions",
    "Office of Secretary of Defense",
    "Office of the Deputy Assistant Secretary of Defense for Counter-Narcotics",
    "Office of the Secretary of Defense (Chief Information Officer)",
    "Outside DoD",
    "Pentagon Force Protection Agency",
    "U.S. Coast Guard",
    "U.S. Mission to NATO",
    "Under Secretary of Defense (Comptroller)/Chief Financial Officer",
    "Under Secretary of Defense for Acquisition, Technology and Logistics",
    "Under Secretary of Defense for Intelligence",
    "Under Secretary of Defense for Personnel and Readiness",
    "Under Secretary of Defense for Policy",
    "United States Africa Command (USAFRICOM)",
    "United States Air Force",
    "United States Army",
    "United States Central Command (USCENTCOM)",
    "United States Cyber Command (USCYBERCOM)",
    "United States European Command (USEUCOM)",
    "United States Forces Korea",
    "United States Joint Forces Command",
    "United States Northern Command (USNORTHCOM)",
    "United States Pacific Command (USPACOM)",
    "United States Southern Command (USSOUTHCOM)",
    "United States Space Command (USSPACECOM)",
    "United States Special Operations Command (USSOCOM)",
    "United States Strategic Command (USSTRATCOM)",
    "United States Transportation Command (USTRANSCOM)",
    "US Army NATO",
    "Washington Headquarters Service",
    "White House Communications Agency",
    "If Other, enter the Component name in the box below.",
  ];

  // const opts = labels.map((obj) => {
  //   let lower = lowerCase(obj);
  //   let fieldName = lower.replace(/[\W_]+/g, "_");
  //   let json = `1_dod_comp_name_${fieldName}`;
  //   return json;
  // });

  // console.log(opts);

  const options = labels.map((lbl, index) => ({
    label:lbl,
    value:config.fieldIds[index]
  }))


  return (
    <EditableSelect
      {...elementProps}
      style={style}
      fieldIds={config.fieldIds}
      fieldNames={config.fieldNames}
      options={[
        { label: config.label, value: config.value },
        ...options
      ]}
    />
  );
};

